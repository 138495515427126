import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Row, Table } from "reactstrap";

import { convertValidDate, convertValidTime, snakeToCapitalizedWords } from "../../../../helpers/format_helper";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import Loader from "../../../../Components/Common/Loader";
import { Actions } from "../../../../Components/constants/common";
import { getImportSyncRecord } from "../../../../store/actions";
import withRouter from "../../../../Components/Common/withRouter";
import BreadCrumbCustom from "../../../../Components/Common/BreadCrumbCustom";
import JsonView from "react18-json-view";
import LottieIcon from "../../../../Components/Common/LottieIcon";
import SyncComponent from "../../../../Components/Common/SynceComponent";
import ImageCustom from "../../../../Components/Common/ImageCustom";

const SyncRecordDetails = (props) => {
  document.title = "Sync Record Details | OptiWarehouse";
  const {
    router: { navigate, location, params, t },
  } = props;
  const dispatch = useDispatch();
  const { id } = params;

  console.log(id);

  const { importSyncRecord, recordsLoading, recordsAction, recordMessage, recordsError } = useMemoizedSelector(
    (state) => ({
      importSyncRecord: state.SyncRecords.importSyncRecord,
      recordsLoading: state.SyncRecords.loading,
      recordsAction: state.SyncRecords.action,
      recordsError: state.SyncRecords.error,
      recordMessage: state.SyncRecords.message,
    }),
  );

  const { saleChannels } = useMemoizedSelector((state) => ({
    saleChannels: state.SaleChannels.saleChannels,
  }));

  useEffect(() => {
    if (id) {
      dispatch(getImportSyncRecord(id, true));
    }
  }, []);

  // Sorting Logic
  const sortedRecords = [...importSyncRecord].sort((a, b) => {
    const aMatches = a.id.startsWith(a.connection_id);
    const bMatches = b.id.startsWith(b.connection_id);

    if (aMatches && !bMatches) return -1;
    if (!aMatches && bMatches) return 1;
    return 0;
  });

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumbCustom text="Back to sync record list" navigateTo="/sync-records" />
        <Row>
          <Col lg={12}>
            {recordsAction === Actions.GET ? (
              <Loader />
            ) : recordsError ? (
              <div className="text-center">
                <LottieIcon src="https://cdn.lordicon.com/msoeawqm.json" style={{ width: "130px", height: "130px" }} />
                <div>
                  <h4>{t(recordsError)}</h4>
                </div>
              </div>
            ) : (
              <Fragment>
                <h5 className="card-title mb-3">
                  Sync Record Details of {id}
                  <span className="badge bg-primary rounded-pill align-middle ms-1 filter-badge">
                    {importSyncRecord ? importSyncRecord.length : 0}
                  </span>
                </h5>
                <Row className="mt-4">
                  <Col lg={12}>
                    <SyncComponent sync_record_id={id} />
                  </Col>
                </Row>
                {sortedRecords.map((item, index) => {
                  const saleChannel = saleChannels.find((saleChannel) => saleChannel.key === item?.channel);
                  return (
                    <Card key={index}>
                      <CardHeader>
                        <h5 className="card-title">
                          {index + 1}. {item?.transformed_record_id || "No ID"}
                        </h5>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col lg={6}>
                            <div className="table-responsive">
                              <Table className="table mb-0 table-borderless" style={{ tableLayout: "fixed" }}>
                                <tbody>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Channel:</span>
                                    </th>
                                    <td>
                                      <div className="tw-flex tw-items-center tw-space-x-2">
                                        <ImageCustom
                                          image={saleChannel?.logo}
                                          name={saleChannel?.name}
                                          avatarSize={"avatar-xxs"}
                                          isPreview={false}
                                        />
                                        <span className="tw-font-semibold">
                                          {snakeToCapitalizedWords(item?.channel ?? "")}
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Connection id:</span>
                                    </th>
                                    <td>{item?.connection_id || "---"}</td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Record type:</span>
                                    </th>
                                    <td>{item?.record_type || "---"}</td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Raw Record Version:</span>
                                    </th>
                                    <td>{item?.raw_record_version || "---"}</td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Raw Data:</span>
                                    </th>
                                    <td>
                                      {item?.raw_data ? (
                                        <JsonView src={item.raw_data} collapsed={true} enableClipboard={true} />
                                      ) : (
                                        "---"
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="table-responsive">
                              <Table className="table mb-0 table-borderless" style={{ tableLayout: "fixed" }}>
                                <tbody>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Response:</span>
                                    </th>
                                    <td>
                                      {item?.response ? (
                                        <JsonView src={item.response} collapsed={true} enableClipboard={true} />
                                      ) : (
                                        "---"
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Message:</span>
                                    </th>
                                    <td>{item?.response_message || "---"}</td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Transformed Record Version:</span>
                                    </th>
                                    <td>{item?.transformed_record_version || "---"}</td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Transformed Data:</span>
                                    </th>
                                    <td>
                                      {item?.transformed_data ? (
                                        <JsonView src={item.transformed_data} collapsed={true} enableClipboard={true} />
                                      ) : (
                                        "---"
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Created At:</span>
                                    </th>
                                    <td>
                                      {convertValidDate(item?.created_at)},{" "}
                                      <small>{convertValidTime(item?.created_at)}</small>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Updated At:</span>
                                    </th>
                                    <td>
                                      {convertValidDate(item?.updated_at)},{" "}
                                      <small>{convertValidTime(item?.updated_at)}</small>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  );
                })}
              </Fragment>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(SyncRecordDetails);
