import flagus from "../assets/images/flags/us.svg";
import flagvn from "../assets/images/flags/vn.svg";
import flages from "../assets/images/flags/es.svg";
import flagfr from "../assets/images/flags/fr.svg";
import flagde from "../assets/images/flags/de.svg";
import flagit from "../assets/images/flags/it.svg";
import flagpt from "../assets/images/flags/pt.svg";
import flagru from "../assets/images/flags/ru.svg";
import flagchina from "../assets/images/flags/china.svg";
import flagjp from "../assets/images/flags/jp.svg";
import flagkr from "../assets/images/flags/kr.svg";

const languages = {
  en: {
    label: "English",
    flag: flagus,
  },
  vn: {
    label: "Tiếng Việt",
    flag: flagvn,
  },
  es: {
    label: "Tiếng Spanish",
    flag: flages,
  },
  fr: {
    label: "Tiếng French",
    flag: flagfr,
  },
  de: {
    label: "Tiếng German",
    flag: flagde,
  },
  it: {
    label: "Tiếng Italian",
    flag: flagit,
  },
  pt: {
    label: "Tiếng Portuguese",
    flag: flagpt,
  },
  ru: {
    label: "Tiếng Russian",
    flag: flagru,
  },
  china: {
    label: "Tiếng Chinese",
    flag: flagchina,
  },
  jp: {
    label: "Tiếng Japanese",
    flag: flagjp,
  },
  kr: {
    label: "Tiếng Korean",
    flag: flagkr,
  },
};

export default languages;
