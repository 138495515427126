import {
  GET_IMPORT_FETCHEVENT,
  GET_IMPORT_FETCHEVENT_FAIL,
  GET_IMPORT_FETCHEVENT_SUCCESS,
  LIST_FETCHEVENT,
  LIST_FETCHEVENT_FAIL,
  LIST_FETCHEVENT_SUCCESS,
} from "./actionType";

export const listFetchEvent = (options) => ({
  type: LIST_FETCHEVENT,
  payload: options,
});

export const listFetchEventFail = (error) => ({
  type: LIST_FETCHEVENT_FAIL,
  payload: error,
});

export const listFetchEventSuccess = (fetchEvents) => ({
  type: LIST_FETCHEVENT_SUCCESS,
  payload: fetchEvents,
});

export const getImportFetchEvent = (fetch_id) => ({
  type: GET_IMPORT_FETCHEVENT,
  payload: { fetch_id },
});

export const getImportFetchEventFail = (error) => ({
  type: GET_IMPORT_FETCHEVENT_FAIL,
  payload: error,
});

export const getImportFetchEventSuccess = (importFetchEvent) => ({
  type: GET_IMPORT_FETCHEVENT_SUCCESS,
  payload: importFetchEvent,
});
