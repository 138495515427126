import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";

import { GET_IMPORT_FETCHEVENT, LIST_FETCHEVENT } from "./actionType";

import {
  listFetchEventSuccess,
  listFetchEventFail,
  getImportFetchEventSuccess,
  getImportFetchEventFail,
} from "./action";

import { getListFetchEventsAPI, getFetchEventAPI } from "../../../../helpers/service_helper";

function* listFetchEvent({ payload: { ...res } }) {
  try {
    const response = yield call(getListFetchEventsAPI, { ...res });
    yield put(listFetchEventSuccess(response));
  } catch (error) {
    yield put(listFetchEventFail(error));
  }
}

function* getImportFetchEvent({ payload: { fetch_id } }) {
  try {
    const response = yield call(getFetchEventAPI, { fetch_id });
    yield put(getImportFetchEventSuccess(response));
  } catch (error) {
    yield put(getImportFetchEventFail(error));
  }
}


export function* watchGetImportFetchEvent() {
  yield takeEvery(GET_IMPORT_FETCHEVENT, getImportFetchEvent);
}

export function* watchGetFetchEvents() {
  yield takeEvery(LIST_FETCHEVENT, listFetchEvent);
}

function* fetchEventsSaga() {
  yield all([fork(watchGetFetchEvents), fork(watchGetImportFetchEvent)]);
}

export default fetchEventsSaga;
