import { Form, Formik } from "formik";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import ScrollToError from "../../../../Components/Common/ScrollToError";
import { Card, CardBody, CardHeader } from "reactstrap";
import SelectTheme from "../../../../Components/Common/SelectTheme";
import InputTheme from "../../../../Components/Common/InputTheme";
import { t } from "i18next";
import { Popover } from "antd";
import CKEditorCustom from "../../../../Components/Common/CKEditorCustom";
import * as Yup from "yup";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";

export default function FormBlogLanguage({ formRef, dataDefault = {} }) {
  const [saveData, setSaveData] = useState(dataDefault);

  const validation = {
    enableReinitialize: true,
    initialValues: {
      title: saveData?.title ?? null,
      content: saveData?.content ?? "",
      description: saveData?.description ?? null,
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t("Please Enter Blog Title")),
      content: Yup.string().required(t("Please Enter Blog Content")),
      description: Yup.string().required(t("Please Enter Blog Description")),
    }),
    onSubmit: async (values) => {
      console.log(values);
    },
  };
  const refForm = useRef();
  useImperativeHandle(formRef, () => ({
    onSubmitForm: async () => {
      try {
        await refForm.current.submitForm();
        const checkValidation = await refForm.current.isValid;
        const values = saveData;
        // values.locale = code;
        if (checkValidation) {
          return values;
        } else {
          return null;
          // throw new ('Form không hợp lệ')();
        }
      } catch (error) {
        return null;
      }
    },
    // onSetValues: () => {
    //   const values = refForm.current.values;
    //   console.log("values", saveData, values);
    //   // return values;
    //   return saveData;
    // },
  }));

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={validation.initialValues}
        validationSchema={validation.validationSchema}
        onSubmit={validation.onSubmit}
        innerRef={refForm}
      >
        {({ values, setFieldValue, errors, touched }) => {
          return (
            <Form>
              <div className="d-flex gap-3">
                <div className="flex-grow-1">
                  <Card id="basic-information">
                    <CardBody>
                      <InputTheme
                        name={"title"}
                        placeholder="Enter blog title"
                        label={"Title"}
                        onTrigger={(value) => {
                          setSaveData((data) => ({ ...data, title: value }));
                        }}
                      />
                      <InputTheme
                        name={"description"}
                        placeholder="Enter blog description"
                        label={"Description"}
                        onTrigger={(value) => {
                          setSaveData((data) => ({ ...data, description: value }));
                        }}
                      />

                      <div id="blog-content" className="mb-3">
                        <h5 className="fs-14 mb-1 hstack justify-content-between">
                          {t("Content")}
                          <Popover
                            content={
                              <div style={{ width: "850px", height: "400px", overflow: "auto" }}>
                                {values?.content ? (
                                  <div className="ck-content" dangerouslySetInnerHTML={{ __html: values?.content }} />
                                ) : (
                                  ""
                                )}
                              </div>
                            }
                            title={t("Preview")}
                            placement="right"
                          >
                            <div className="hstack gap-2">
                              <span>
                                <i className="ri-eye-fill align-bottom color-sub-text-secondary fs-18" />
                              </span>
                            </div>
                          </Popover>
                        </h5>
                        <div
                          style={{
                            border: `1px solid ${errors.description && touched.description ? "#f06548" : "#d1d1d1"}`,
                            backgroundColor: "#fff",
                          }}
                        >
                          <CKEditorCustom
                            config={{
                              language: "vi",
                              resize: {
                                minHeight: "300px", // Set minimum height
                                maxHeight: "600px", // Set maximum height
                              },
                            }}
                            imagePrefix="blogs"
                            value={values?.content}
                            onChangeValue={(data) => {
                              setFieldValue("content", data);
                              setSaveData((d) => ({ ...d, content: data }));
                            }}
                          />
                        </div>
                        {errors.content && touched.content && (
                          <div className="position-absolute fs-10" style={{ color: "#f06548" }}>
                            {errors.content}
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
