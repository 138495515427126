import { Dropdown, Empty, Input, Tabs, theme } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import FormBlogLanguage from "./FormBlogLanguage";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import { t } from "i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getListBlogLocale } from "../../../../helpers/service_helper";
import BreadCrumbCustom from "../../../../Components/Common/BreadCrumbCustom";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import languagesCountry from "../../../../common/data/languagesCountry";
import { toast } from "react-toastify";
import Loader from "../../../../Components/Common/Loader";
import ModalContainer from "../../../../Components/Common/ModalContainer";
import SelectTheme from "../../../../Components/Common/SelectTheme";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { blogsAPI } from "../../../../helpers/resource_helper";
import { useDispatch } from "react-redux";
import { addNewBlog, deleteBlog, updateBlog } from "../../../../store/actions";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { Actions } from "../../../../Components/constants/common";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import FormBlogOrigin from "./FormBlogOrigin";
import InputTheme from "../../../../Components/Common/InputTheme";
import languages from "../../../../common/languages";
import { get } from "lodash";

export default function BlogLanguage() {
  const { id } = useParams();
  const formRef = useRef();
  const formOriginRef = useRef();
  const navigator = useNavigate();
  const [tabs, setTabs] = useState([]);
  const { state, pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activeKeyTab, setActiveKeyTab] = useState("");
  const dispatch = useDispatch();

  const [deleteModal, setDeleteModal] = useState(null);
  const handleCloseDeleteModal = () => {
    if (!blogsLoading) {
      setDeleteModal(false);
    }
  };

  const { blogState, blogsLoading, blogsSuccess, blogsAction, blogsMsg } = useMemoizedSelector((state) => ({
    blogState: state.Blogs.blog,
    blogsLoading: state.Blogs.loading,
    blogsAction: state.Blogs.action,
    blogsSuccess: state.Blogs.success,
    blogsError: state.Blogs.error,
    blogsMsg: state.Blogs.message,
  }));

  useEffect(() => {
    if (state?.isCreate && loading) {
      setIsOpen(true);
    }
  }, [state?.isCreate, loading]);

  const handleConvertTabs = (res) => {
    const org_blog = res?.org_blog;
    const blogOrigin = {
      label: (
        <div className="d-flex align-items-center">
          <img
            src={get(languages, `${org_blog?.locale}.flag`)}
            alt={org_blog.id}
            className="me-2 rounded"
            height="18"
          />
          <div className="text-truncate-two-lines mx-2">{get(languages, `${org_blog?.locale}.label`) || "---"}</div>
        </div>
      ),
      key: org_blog.id,
      code: org_blog?.locale,
      data: org_blog,
      closable: false,
      children:
        res?.org_blog.locale === "en" ? (
          <FormBlogOrigin formRef={formOriginRef} dataDefault={org_blog} />
        ) : (
          <FormBlogLanguage formRef={formRef} dataDefault={org_blog} />
        ),
    };
    const mapItems = res?.children
      .filter((item) => item.locale !== "en")
      .map((i) => {
        return {
          label: (
            <div className="d-flex align-items-center">
              <img src={get(languages, `${i?.locale}.flag`)} alt={org_blog.id} className="me-2 rounded" height="18" />
              <div className="text-truncate-two-lines mx-2">{get(languages, `${i?.locale}.label`) || "---"}</div>
            </div>
          ),
          key: i.id,
          code: i?.locale,
          data: i,
          children: <FormBlogLanguage formRef={formRef} dataDefault={i} />,
        };
      });

    return [blogOrigin, ...mapItems];
  };

  useEffect(() => {
    const getListBlog = async () => {
      if (id) {
        setLoading(true);
        try {
          const response = await blogsAPI.get({ id });
          const newTabs = handleConvertTabs(response);
          setLoading(false);
          setTabs(newTabs);
          if (state?.id) {
            setActiveKeyTab(state?.id);
          } else {
            setActiveKeyTab(newTabs[0].key);
          }
        } catch (error) {
          setLoading(false);
          toast.error(error);
        }
      } else {
        setTabs([
          {
            label: (
              <div className="d-flex align-items-center">
                <img src={get(languages, `en.flag`)} alt={"en"} className="me-2 rounded" height="18" />
                <div className="text-truncate-two-lines">
                  {languagesCountry?.find((item) => item.code === "en")?.name || "---"}
                </div>
              </div>
            ),
            key: "en",
            code: "en",
            data: undefined,
            closable: false,
            children: <FormBlogOrigin formRef={formOriginRef} dataDefault={{}} />,
          },
        ]);
        setActiveKeyTab("en");
      }
    };
    getListBlog();
  }, [id]);

  const remove = (targetKey) => {
    let newActiveKey = activeKeyTab;
    let lastIndex = -1;
    tabs.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = tabs.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setTabs(newPanes);
    setActiveKeyTab(newActiveKey);
  };

  const onEdit = (targetKey, action) => {
    if (action === "add") {
      console.log("Sdfd");
    } else {
      remove(targetKey);
    }
  };

  const [saveLanguageSettings, setSaveLanguageSettings] = useState([]);
  useEffect(() => {
    const settingsLanguage = JSON.parse(localStorage.getItem("settings"))?.dataShop?.setting_value?.languages;
    setSaveLanguageSettings(settingsLanguage || []);
  }, []);

  const menuProps = {
    items: saveLanguageSettings
      ?.filter((i) => i.code !== "en")
      ?.map((item) => ({
        label: item.name,
        key: item.code,
        icon: <img src={get(languages, `${item?.code}.flag`)} alt={item.code} className="me-2 rounded" height="18" />,
      })),
    onClick: async (e) => {
      const dataCode = get(languages, e.key);
      // languagesCountry.find((item) => item.code === e.key);
      if (tabs.find((tab) => tab.code === e.key)) {
        toast.error(t("Language exist!"));
      } else {
        let values;
        if (activeKeyTab === id || activeKeyTab === "en") {
          values = await formOriginRef.current.onSubmitForm();
        } else {
          values = await formRef?.current?.onSubmitForm();
        }
        const newTabs = handleChangeTabs(tabs, activeKeyTab, values);
        setTabs((tabs) => [
          ...newTabs,
          {
            label: (
              <div className="d-flex align-items-center">
                <img src={dataCode?.flag} alt={e.key} className="me-2 rounded" height="18" />
                <div className="text-truncate-two-lines mx-2">{dataCode?.label || "---"}</div>
              </div>
            ),
            code: e.key,
            key: e.key,
            children: <FormBlogLanguage formRef={formRef} dataDefault={{}} key={e.key} />,
          },
        ]);
        setIsOpen(false);
        setActiveKeyTab(e.key);
      }
    },
  };

  function isEqual(obj1, obj2) {
    // Kiểm tra xem có phải là object không
    if (!obj1 || !obj2) return false;

    // Kiểm tra các trường bắt buộc
    const requiredFields = ["title", "content", "description"];

    for (const field of requiredFields) {
      // Sử dụng Object.prototype.hasOwnProperty.call thay vì gọi trực tiếp
      if (!Object.prototype.hasOwnProperty.call(obj1, field) || !Object.prototype.hasOwnProperty.call(obj2, field)) {
        return false;
      }
      if (obj1[field] !== obj2[field]) {
        return false;
      }
    }

    return true;
  }

  const handleChangeTabs = (tabs, activeKeyTab, values) => {
    const newTabs = tabs.map((tab) => {
      if (tab.key === activeKeyTab) {
        if (values === null) {
          console.log("tabs, activeKeyTab, values", tabs, activeKeyTab, values);
          return {
            ...tab,
            data: null,
            label: (
              <div className="d-flex align-items-center">
                <img src={get(languages, `${tab?.code}.flag`)} alt={tab?.code} className="me-2 rounded" height="18" />
                <div className="text-truncate-two-lines mx-2">{get(languages, `${tab?.code}.label`) || "---"}</div>
                <i className="ri-error-warning-fill text-danger"></i>
              </div>
            ),
            ...(tab?.code === "en"
              ? {
                  children: <FormBlogOrigin formRef={formOriginRef} dataDefault={{}} key={tab?.code} />,
                }
              : {
                  children: <FormBlogLanguage formRef={formRef} dataDefault={{}} key={tab?.code} />,
                }),
          };
        } else {
          const checkValue = tabs.find((t) => isEqual(t.data, values));
          console.log(checkValue, values, activeKeyTab);
          return {
            ...tab,
            data: checkValue ? (tab?.data ? tab?.data : values) : values,
            ...(values
              ? {
                  // data: { ...values, locale: tab.code },
                  label: (
                    <div className="d-flex align-items-center">
                      <img
                        src={get(languages, `${tab?.code}.flag`)}
                        alt={tab?.code}
                        className="me-2 rounded"
                        height="18"
                      />
                      <div className="text-truncate-two-lines mx-2">
                        {get(languages, `${tab?.code}.label`) || "---"}
                      </div>
                    </div>
                  ),
                }
              : {
                  label: (
                    <div className="d-flex align-items-center">
                      <img
                        src={get(languages, `${tab?.code}.flag`)}
                        alt={tab?.code}
                        className="me-2 rounded"
                        height="18"
                      />
                      <div className="text-truncate-two-lines mx-2">
                        {get(languages, `${tab?.code}.label`) || "---"}
                      </div>
                      <i className="ri-error-warning-fill text-danger"></i>
                    </div>
                  ),
                }),
            ...(tab?.code === "en"
              ? {
                  children: (
                    <FormBlogOrigin
                      formRef={formOriginRef}
                      dataDefault={checkValue ? (tab?.data ? tab?.data : values) : values}
                      key={tab?.code}
                    />
                  ),
                }
              : {
                  children: (
                    <FormBlogLanguage
                      formRef={formRef}
                      dataDefault={checkValue ? (tab?.data ? tab?.data : values) : values}
                      key={tab?.code}
                    />
                  ),
                }),
          };
        }
      } else {
        return {
          ...tab,
          ...(tab?.data
            ? {
                label: (
                  <div className="d-flex align-items-center">
                    <img
                      src={get(languages, `${tab?.code}.flag`)}
                      alt={tab?.code}
                      className="me-2 rounded"
                      height="18"
                    />
                    <div className="text-truncate-two-lines mx-2">{get(languages, `${tab?.code}.label`) || "---"}</div>
                  </div>
                ),
              }
            : {
                label: (
                  <div className="d-flex align-items-center">
                    <img
                      src={get(languages, `${tab?.code}.flag`)}
                      alt={tab?.code}
                      className="me-2 rounded"
                      height="18"
                    />
                    <div className="text-truncate-two-lines mx-2">{get(languages, `${tab?.code}.label`) || "---"}</div>
                    <i className="ri-error-warning-fill text-danger"></i>
                  </div>
                ),
              }),
          ...(tab?.code === "en"
            ? {
                children: <FormBlogOrigin formRef={formOriginRef} dataDefault={tab?.data} key={tab?.code} />,
              }
            : {
                children: <FormBlogLanguage formRef={formRef} dataDefault={tab?.data} key={tab?.code} />,
              }),
        };
      }
    });
    return newTabs;
  };

  const { Search } = Input;
  const { useToken } = theme;
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  // const handleChangeSearchLanguageSettings = (value) => {
  //   if (value.trim()) {
  //     const newValue = settingsLanguage.filter((i) => i.name.toLowerCase().includes(value.toLowerCase()));
  //     setSaveLanguageSettings(newValue);
  //   } else {
  //     setSaveLanguageSettings(settingsLanguage);
  //   }
  // };

  return (
    <div className="page-content">
      <Container fluid>
        <DeleteModal
          show={deleteModal}
          description={t("Do you want to remove this blog?")}
          onDeleteClick={() => {
            if (id) {
              dispatch(
                deleteBlog({ id }, () => {
                  navigator("/blogs");
                }),
              );
            }
          }}
          onCloseClick={handleCloseDeleteModal}
          loading={blogsLoading && blogsAction === Actions.DELETE}
        />
        <BreadCrumbCustom text="Back to blog list" navigateTo="/blogs" t={t}>
          <div className="hstack gap-2">
            {tabs[0]?.data?.id === tabs[0]?.data?.org_blog_id && (
              <Dropdown
                menu={menuProps}
                trigger={["click"]}
                dropdownRender={(menu) => (
                  <div style={contentStyle}>
                    <Search
                      placeholder="search language"
                      allowClear
                      // onSearch={(values) => {
                      //   handleChangeSearchLanguageSettings(values);
                      // }}
                      // onChange={(e) => {
                      //   handleChangeSearchLanguageSettings(e.target.value);
                      // }}
                      style={{
                        padding: "5px",
                      }}
                    />
                    {saveLanguageSettings?.length === 0 && (
                      <div
                        style={{
                          marginTop: "20px",
                          textAlign: "center",
                        }}
                      >
                        Vui lòng cài đặt thêm ngôn ngữ
                      </div>
                    )}
                    {menu}
                  </div>
                )}
              >
                <ButtonTheme type="button" className="btn btn-outline-primary w-sm">
                  {t("Create Language")}
                </ButtonTheme>
              </Dropdown>
            )}
            {tabs.length > 0 && (
              <ButtonTheme
                type="button"
                className="btn btn-outline-primary w-sm"
                loading={blogsAction === Actions.UPDATE || (blogsAction === Actions.CREATE && blogsLoading)}
                disabled={blogsAction === Actions.UPDATE || (blogsAction === Actions.CREATE && blogsLoading)}
                onClick={async () => {
                  // try {
                  let values;
                  if (activeKeyTab === id || activeKeyTab === "en") {
                    values = await formOriginRef.current?.onSubmitForm();
                  } else {
                    values = await formRef.current?.onSubmitForm();
                  }
                  const mapDataTab = tabs.map((tab) => {
                    const checkValue = tabs.find((t) => isEqual(t.data, values));
                    if (tab.key === activeKeyTab) {
                      if (values) {
                        return checkValue ? (tab?.data ? tab?.data : values) : values;
                      } else return null;
                    } else return tab.data;
                  });
                  console.log("mapDataTab", mapDataTab);

                  if (mapDataTab.filter((tab) => !tab).length === 0) {
                    const newTabs = tabs.map((tab) => {
                      if (tab.key === activeKeyTab) return { ...values, locale: tab.code };
                      else return { ...tab.data, locale: tab.code };
                    });
                    const [blog_origin, ...res] = newTabs;
                    if (id) {
                      dispatch(
                        updateBlog(
                          id,
                          { org_blog: blog_origin, children: res.map((r) => ({ ...r, org_blog_id: id })) },
                          (data) => {
                            const newTabs = handleConvertTabs(data);
                            setTabs(newTabs);
                            setActiveKeyTab(newTabs[0].key);
                          },
                        ),
                      );
                    } else {
                      dispatch(
                        addNewBlog({ org_blog: blog_origin, children: res }, (data) => {
                          navigator(`/blogs/${data.org_blog.id}/edit`);
                        }),
                      );
                    }
                  } else {
                    setTabs((tabs, index) => {
                      const newTabs = tabs.map((tab) => {
                        if (tab.key === activeKeyTab) {
                          return {
                            ...tab,
                            ...(values
                              ? {
                                  data: { ...values, locale: tab.code },
                                  label: (
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={get(languages, `${tab?.code}.flag`)}
                                        alt={tab?.code}
                                        className="me-2 rounded"
                                        height="18"
                                      />
                                      <div className="text-truncate-two-lines mx-2">
                                        {get(languages, `${tab?.code}.label`) || "---"}
                                      </div>
                                    </div>
                                  ),
                                }
                              : {
                                  label: (
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={get(languages, `${tab?.code}.flag`)}
                                        alt={tab?.code}
                                        className="me-2 rounded"
                                        height="18"
                                      />
                                      <div className="text-truncate-two-lines mx-2">
                                        {get(languages, `${tab?.code}.label`) || "---"}
                                      </div>
                                      <i className="ri-error-warning-fill text-danger"></i>
                                    </div>
                                  ),
                                }),
                          };
                        } else {
                          return {
                            ...tab,
                            ...(tab?.data
                              ? {
                                  label: (
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={get(languages, `${tab?.code}.flag`)}
                                        alt={tab?.code}
                                        className="me-2 rounded"
                                        height="18"
                                      />
                                      <div className="text-truncate-two-lines mx-2">
                                        {get(languages, `${tab?.code}.label`) || "---"}
                                      </div>
                                    </div>
                                  ),
                                }
                              : {
                                  label: (
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={get(languages, `${tab?.code}.flag`)}
                                        alt={tab?.code}
                                        className="me-2 rounded"
                                        height="18"
                                      />
                                      <div className="text-truncate-two-lines mx-2">
                                        {get(languages, `${tab?.code}.label`) || "---"}
                                      </div>
                                      <i className="ri-error-warning-fill text-danger"></i>
                                    </div>
                                  ),
                                }),
                          };
                        }
                      });
                      return newTabs;
                    });
                  }
                }}
              >
                {t(id ? "Update" : "Create")}
              </ButtonTheme>
            )}
            {tabs.length === 1 && id && (
              <ButtonTheme
                type="button"
                className="btn btn-danger w-sm"
                onClick={() => {
                  setDeleteModal(true);
                }}
                disabled={blogsAction === Actions.DELETE && blogsLoading}
              >
                {t("Delete")}
              </ButtonTheme>
            )}
          </div>
        </BreadCrumbCustom>
        <Card id="basic-information">
          <CardHeader>
            <h5 className="card-title">{t("Lists locale blog")}</h5>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Loader />
            ) : (
              <div>
                {tabs.length > 0 ? (
                  <Tabs
                    hideAdd
                    type="editable-card"
                    items={tabs}
                    activeKey={activeKeyTab}
                    onChange={async (activeKey) => {
                      // const values = formRef.current.onSetValues();
                      let values;
                      if (activeKeyTab === id || activeKeyTab === "en") {
                        values = await formOriginRef.current.onSubmitForm();
                      } else {
                        values = await formRef.current.onSubmitForm();
                      }
                      setTabs((tabs, index) => {
                        return handleChangeTabs(tabs, activeKeyTab, values);
                      });
                      setActiveKeyTab(activeKey);
                    }}
                    onEdit={onEdit}
                  />
                ) : (
                  <Empty description={false} />
                )}
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
