import {
  GET_COLOR_SHOP_INFO,
  GET_COLOR_SHOP_INFO_FAIL,
  GET_COLOR_SHOP_INFO_SUCCESS,
  GET_DEFAULT_PRINT,
  GET_DEFAULT_PRINT_FAIL,
  GET_DEFAULT_PRINT_SUCCESS,
  GET_SHOP_INFO,
  GET_SHOP_INFO_FAIL,
  GET_SHOP_INFO_SUCCESS,
  UPDATE_COLOR_SHOP_INFO,
  UPDATE_COLOR_SHOP_INFO_FAIL,
  UPDATE_COLOR_SHOP_INFO_SUCCESS,
  UPDATE_DEFAULT_PRINT,
  UPDATE_DEFAULT_PRINT_FAIL,
  UPDATE_DEFAULT_PRINT_SUCCESS,
  UPDATE_LOCATION_ID,
  UPDATE_SHOP_INFO,
  UPDATE_SHOP_INFO_FAIL,
  UPDATE_SHOP_INFO_SUCCESS,
  GET_LANGUAGE,
  GET_LANGUAGE_SUCCESS,
  GET_LANGUAGE_FAIL,
  UPDATE_LANGUAGE,
  UPDATE_LANGUAGE_SUCCESS,
  UPDATE_LANGUAGE_FAIL,
} from "./actionType";

export const getColorInfoShop = () => ({
  type: GET_COLOR_SHOP_INFO,
});
export const getColorInfoShopSuccess = (payload) => ({
  type: GET_COLOR_SHOP_INFO_SUCCESS,
  payload: payload,
});
export const getColorInfoShopError = (error) => ({
  type: GET_COLOR_SHOP_INFO_FAIL,
  payload: error,
});

export const updateColorInfoShop = (data) => ({
  type: UPDATE_COLOR_SHOP_INFO,
  payload: data
});
export const updateColorInfoShopSuccess = (payload) => ({
  type: UPDATE_COLOR_SHOP_INFO_SUCCESS,
  payload: payload,
});
export const updateColorInfoShopError = (error) => ({
  type: UPDATE_COLOR_SHOP_INFO_FAIL,
  payload: error,
});

export const getInfoShop = () => ({
  type: GET_SHOP_INFO,
});

export const getInfoShopSuccess = (payload) => ({
  type: GET_SHOP_INFO_SUCCESS,
  payload: payload,
});

export const getInfoShopError = (error) => ({
  type: GET_SHOP_INFO_FAIL,
  payload: error,
});

export const updateInfoShop = (data, handleAfterSubmit = () => {}) => ({
  type: UPDATE_SHOP_INFO,
  payload: { data, handleAfterSubmit },
});

export const updateInfoShopSuccess = (data) => ({
  type: UPDATE_SHOP_INFO_SUCCESS,
  payload: data,
});

export const updateInfoShopFail = (error) => ({
  type: UPDATE_SHOP_INFO_FAIL,
  payload: error,
});

export const getDefaultPrintShop = () => ({
  type: GET_DEFAULT_PRINT,
});
export const geDefaultPrintSuccess = (payload) => ({
  type: GET_DEFAULT_PRINT_SUCCESS,
  payload: payload,
});

export const getDefaultPrintError = (error) => ({
  type: GET_DEFAULT_PRINT_FAIL,
  payload: error,
});

export const updateDefaultPrintShop = (payload, handleAfterClick) => ({
  type: UPDATE_DEFAULT_PRINT,
  payload: { data: payload, handleAfterClick },
});
export const updateDefaultPrintSuccess = (payload) => ({
  type: UPDATE_DEFAULT_PRINT_SUCCESS,
  payload: payload,
});

export const updateDefaultPrintError = (error) => ({
  type: UPDATE_DEFAULT_PRINT_FAIL,
  payload: error,
});

export const updateLocationUser = (payload) => ({
  type: UPDATE_LOCATION_ID,
  payload: payload,
});

//Language===============
export const getLanguage = () => ({
  type: GET_LANGUAGE,
});

export const getLanguageSuccess = (payload) => ({
  type: GET_LANGUAGE_SUCCESS,
  payload,
});

export const getLanguageFail = (error) => ({
  type: GET_LANGUAGE_FAIL,
  payload: error,
});

export const updateLanguage = (payload, handleAfterUpdate = () => {}) => ({
  type: UPDATE_LANGUAGE,
  payload: { data: payload, handleAfterUpdate },
});

export const updateLanguageSuccess = (payload) => ({
  type: UPDATE_LANGUAGE_SUCCESS,
  payload,
});

export const updateLanguageFail = (error) => ({
  type: UPDATE_LANGUAGE_FAIL,
  payload: error,
});

