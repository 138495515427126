import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { Container, Row, CardHeader, Col, CardBody } from "reactstrap";
import "nouislider/distribute/nouislider.css";

import { convertValidDate, convertValidTime } from "../../../../helpers/format_helper";

import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import ImportCSVModal from "../../../../Components/Common/ImportCSVModal";
import { Actions } from "../../../../Components/constants/common";

import { addNewBlog, deleteBlog, getInfoShop, listBlog, setConfigTour } from "../../../../store/actions";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";
import { Tooltip } from "antd";
import { toast } from "react-toastify";
import languagesCountry from "../../../../common/data/languagesCountry";
import { t } from "i18next";
import ModalContainer from "../../../../Components/Common/ModalContainer";
import FormBlogOrigin from "./FormBlogOrigin";

const BlogsPage = (props) => {
  const {
    router: { navigate, locations, params },
  } = props;
  document.title = `${t("Blogs List")} | OptiWarehouse`;
  const dispatch = useDispatch();

  const { blogs, blogsLoading, blogsAction, blogsLimit, blogsSuccess, blogsPage, blogsTotal, blogsMessage } =
    useMemoizedSelector((state) => ({
      blogs: state.Blogs.blogs,
      blogsMessage: state.Blogs.message,
      blogsAction: state.Blogs.action,
      blogsLimit: state.Blogs.limit,
      blogsPage: state.Blogs.page,
      blogsTotal: state.Blogs.total,
      blogsLoading: state.Blogs.loading,
      blogsSuccess: state.Blogs.success,
      blogsError: state.Blogs.error,
    }));

  const { version } = useMemoizedSelector((state) => ({
    version: state.Version.version,
  }));

  const [blog, setBlog] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [isImportCSV, setIsImportCSV] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const onClickDelete = (blog) => {
    setBlog(blog);
    setDeleteModal(true);
  };

  const handleDeleteBlog = () => {
    if (blog) {
      dispatch(deleteBlog(blog));
    }
  };

  const handleCloseDeleteModal = () => {
    if (!blogsLoading) {
      setDeleteModal(false);
      setBlog(null);
    }
  };

  const headers = ["title", "image", "category", "updated_at", "created_at", "action"];

  useEffect(() => {
    if (!blogsLoading && blogsSuccess && deleteModal && blogsMessage === "Delete blog success") {
      handleCloseDeleteModal();
    }
  }, [blogsLoading, blogsSuccess, blogsMessage]);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const steps = [
    {
      title: t("Upload File"),
      description: t("Put your files here."),
      target: () => ref1.current,
    },
    {
      title: t("Save"),
      description: t("Save your changes."),
      target: () => ref2.current,
    },
  ];

  useEffect(() => {
    dispatch(
      setConfigTour({
        typeTour: "blog",
        steps,
      }),
    );
    return () => {
      dispatch(
        setConfigTour({
          typeTour: "blog",
          steps: [],
        }),
      );
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getInfoShop());
  }, [dispatch]);

  const formRef = useRef();

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={blogs} />
      <ImportCSVModal show={isImportCSV} onCloseClick={() => setIsImportCSV(false)} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteBlog}
        onCloseClick={handleCloseDeleteModal}
        loading={blogsLoading && blogsAction === Actions.DELETE}
        description={t(`Do you want to remove this ${blog?.org_blog_id ? "locale" : ""} blog?`)}
      />
      <ModalContainer
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(false);
        }}
        title={t("Add Blog")}
        size={"lg"}
        scrollable={true}
        actionCustom={
          <div className="mb-3 d-flex gap-2 justify-content-end">
            <ButtonTheme
              type="button"
              className="btn btn-outline-primary w-sm"
              onClick={() => {
                formRef?.current?.handleSubmit();
              }}
            >
              Create
            </ButtonTheme>
            <ButtonTheme
              type="button"
              className="btn btn-outline-danger w-sm"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Close
            </ButtonTheme>
          </div>
        }
      >
        <FormBlogOrigin
          formRef={formRef}
          onHandleAfterSubmit={(values) => {
            dispatch(
              addNewBlog(values, (data) => {
                navigate(`/blogs/${data}`, { state: { idBlog: blog?.org_blog_id } });
              }),
            );
          }}
        />
      </ModalContainer>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div>
              <div className="card">
                <CardHeader className="border-0">
                  <Row className="align-items-center gy-3">
                    <div className="col-sm">
                      <h5 className="card-title mb-0">{t("Blog List")}</h5>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-3 flex-wrap">
                        <ButtonTheme
                          type="button"
                          className="btn btn-light"
                          icon="ri-external-link-fill"
                          onClick={() => {
                            setIsImportCSV(true);
                          }}
                          refTour={ref2}
                        >
                          {t("Import")}
                        </ButtonTheme>
                        <ButtonTheme
                          type="button"
                          className="btn btn-light"
                          icon="ri-file-text-line"
                          onClick={() => setIsExportCSV(true)}
                        >
                          {t("Export")}
                        </ButtonTheme>
                        <ButtonTheme
                          type="button"
                          className="btn btn-primary"
                          id="create-btn"
                          icon="ri-add-line text-white"
                          onClick={() => {
                            navigate("/blogs/new");
                          }}
                          refTour={ref1}
                        >
                          {t("Add Blog")}
                        </ButtonTheme>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <TableContainerCustom
                    filterType={"blogs"}
                    searchPlaceHolder={"Search blog..."}
                    listFilter={[
                      {
                        id: "category.id",
                        type: "selectBox",
                        title: "Category",
                        remote: true,
                        pathUrlLoad: "blog/categories",
                      },
                    ]}
                    headers={headers}
                    contents={blogs?.map((item) => {
                      if (item?.children?.length > 0) {
                        return item;
                      } else {
                        delete item?.children;
                        return item;
                      }
                    })}
                    limitPage={Number(blogsLimit)}
                    currentPage={Number(blogsPage) + 1}
                    totalItems={blogsTotal}
                    loading={blogsLoading && blogsAction === Actions.LIST}
                    showPagination={true}
                    scrollHeight="50vh"
                    onCallData={(dataPayload) => {
                      dispatch(listBlog(dataPayload));
                    }}
                    isSettings={true}
                    listHeader={[
                      {
                        title: t("Blog details"),
                        key: "detail-blog",
                        children: [
                          {
                            title: t("Title"),
                            dataIndex: "title",
                            key: "title",
                            sorter: true,
                            render: (value, row, index) => {
                              return (
                                <Tooltip title={t(row.title)}>
                                  <h5 className="fs-14 mb-1">
                                    <Link to={`/blogs/${row.id}/edit`} className="text-truncate-two-lines link-primary">
                                      {t(row.title)}
                                    </Link>
                                  </h5>
                                </Tooltip>
                              );
                            },
                          },
                          {
                            title: t("Image"),
                            dataIndex: "image",
                            key: "image",
                            render: (value, row, index) => {
                              return <ImageCustom image={row.image?.url} name={row.title} avatarSize={"avatar-sm"} />;
                            },
                          },

                          {
                            title: t("Category"),
                            dataIndex: "category",
                            key: "category",
                            sortKey: "category.name",
                            sorter: true,
                            render: (value, row, index) => {
                              return <div className="text-truncate-two-lines">{t(value?.name) || "---"}</div>;
                            },
                          },
                          {
                            title: t("Language"),
                            dataIndex: "locale",
                            key: "locale",
                            sortKey: "locale",
                            sorter: true,
                            render: (value, row, index) => {
                              return (
                                <div className="text-truncate-two-lines">
                                  {languagesCountry?.find((item) => item.code === value)?.name || "---"}
                                </div>
                              );
                            },
                          },
                          {
                            title: t("Updated at"),
                            dataIndex: "updated_at",
                            key: "updated_at",
                            sorter: true,

                            render: (value, row, index) => {
                              return (
                                <>
                                  {convertValidDate(value)},
                                  <small className="text-muted"> {convertValidTime(value)}</small>
                                </>
                              );
                            },
                          },
                          {
                            title: t("Created at"),
                            dataIndex: "created_at",
                            key: "created_at",
                            sorter: true,
                            defaultSortOrder: "desc",
                            render: (value, row, index) => {
                              return (
                                <>
                                  {convertValidDate(value)},
                                  <small className="text-muted"> {convertValidTime(value)}</small>
                                </>
                              );
                            },
                          },
                          {
                            title: t("Action"),
                            dataIndex: "action",
                            key: "action",
                            render: (value, row, index) => {
                              return (
                                <ActionDropdown
                                  index={index}
                                  actions={[
                                    {
                                      type: "view",
                                      title: "View on web",
                                      onClick: () => {
                                        const link = version?.data?.dict?.shop_info?.setting_value?.store_url;
                                        if (link) {
                                          window.open(`${link}/blogs/${row.slug}`, "_blank");
                                        } else {
                                          toast.error("Please config store URL before use this feature!");
                                        }
                                      },
                                    },
                                    {
                                      type: "view",
                                      onClick: () => {
                                        navigate(`/blogs/${row.id}`);
                                      },
                                    },
                                    {
                                      type: "edit",
                                      onClick: () => {
                                        navigate(`/blogs/${row.id}/edit`);
                                      },
                                    },
                                    {
                                      type: "delete",
                                      onClick: () => {
                                        onClickDelete(row);
                                      },
                                    },
                                  ]}
                                />
                              );
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </CardBody>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(BlogsPage);
