import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import {
  getApiColorShopInfo,
  getDefaultPrintSettings,
  getInfoSetting,
  updateInfoSetting,
  getLanguageSetting,
} from "../../helpers/service_helper";
import {
  geDefaultPrintSuccess,
  getColorInfoShopError,
  getColorInfoShopSuccess,
  getDefaultPrintError,
  getInfoShopError,
  getInfoShopSuccess,
  updateColorInfoShopError,
  updateColorInfoShopSuccess,
  updateDefaultPrintError,
  updateDefaultPrintSuccess,
  getLanguageSuccess,
  getLanguageFail,
  updateLanguageSuccess,
  updateLanguageFail,
} from "./action";
import {
  GET_COLOR_SHOP_INFO,
  GET_DEFAULT_PRINT,
  GET_SHOP_INFO,
  UPDATE_COLOR_SHOP_INFO,
  UPDATE_DEFAULT_PRINT,
  UPDATE_SHOP_INFO,
  GET_LANGUAGE,
  UPDATE_LANGUAGE,
} from "./actionType";
import { settingsDefaultPrintAPI } from "../../helpers/resource_helper";
import { toast } from "react-toastify";

function* getShopInfo() {
  try {
    const response = yield call(getInfoSetting);
    yield put(getInfoShopSuccess(response));
  } catch (error) {
    yield put(getInfoShopError(error));
  }
}

//get language
function* fetchLanguage() {
  try {
    const response = yield call(getLanguageSetting); // Call the API to fetch language settings

    // Extract the languages array
    const languages = response?.setting_value?.languages || [];

    // Dispatch success action with extracted languages
    yield put(getLanguageSuccess(languages));
  } catch (error) {
    console.error("Fetch Language API Error:", error.response?.data || error);
    yield put(getLanguageFail(error)); // Dispatch fail action
  }
}

function* updateLanguage({ payload: { data, handleAfterUpdate } }) {
  try {
    // Transform the data into an array of objects containing code and name
    const transformedData = data.map((lang) => ({
      code: lang.code,
      name: lang.name,
    }));
    console.log("transformedData", transformedData);

    // Call the API with the updated structure
    const response = yield call(updateInfoSetting, "shop_info", { languages: transformedData });
    // Dispatch success action with updated data
    yield put(updateLanguageSuccess(transformedData)); // Dispatch success action with the updated structure

    // Execute the callback if provided
    if (handleAfterUpdate) {
      yield call(handleAfterUpdate);
    }
  } catch (error) {
    console.error("Update Language API Error:", error.response?.data || error);
    yield put(updateLanguageFail(error)); // Dispatch fail action
  }
}

export function* watchGetLanguage() {
  yield takeEvery(GET_LANGUAGE, fetchLanguage);
}

export function* watchUpdateLanguage() {
  yield takeEvery(UPDATE_LANGUAGE, updateLanguage);
}

function* getColorShopInfo() {
  try {
    const response = yield call(getApiColorShopInfo);
    yield put(getColorInfoShopSuccess(response?.setting_value));
  } catch (error) {
    yield put(getColorInfoShopError(error));
  }
}

function* updateColorShopInfo({ payload: data }) {
  try {
    const response = yield call(updateInfoSetting, "color", data);
    yield put(updateColorInfoShopSuccess(response?.setting_value));
    toast.success("Sava color success");
  } catch (error) {
    yield put(updateColorInfoShopError(error));
  }
}

function* getDefaultPrint() {
  try {
    const response = yield call(getDefaultPrintSettings);
    yield put(geDefaultPrintSuccess(response));
  } catch (error) {
    yield put(getDefaultPrintError(error));
  }
}

function* updateDefaultPrint({ payload: { data, handleAfterClick } }) {
  try {
    const response = yield call(settingsDefaultPrintAPI.create, data);
    yield put(updateDefaultPrintSuccess(response));
    yield call(handleAfterClick);
  } catch (error) {
    yield put(updateDefaultPrintError(error));
  }
}

function* updateShopInfo({ payload: { data, handleAfterSubmit } }) {
  try {
    const response = yield call(updateInfoSetting, "shop_info", data);
    yield put(getInfoShopSuccess(response));
    yield call(handleAfterSubmit);
  } catch (error) {
    yield put(getInfoShopError(error));
  }
}

export function* watchGetShopInfo() {
  yield takeEvery(GET_SHOP_INFO, getShopInfo);
}

export function* watchGetColorShopInfo() {
  yield takeEvery(GET_COLOR_SHOP_INFO, getColorShopInfo);
}

export function* watchUpdateColorShopInfo() {
  yield takeEvery(UPDATE_COLOR_SHOP_INFO, updateColorShopInfo);
}

export function* watchGetDefaultPrint() {
  yield takeEvery(GET_DEFAULT_PRINT, getDefaultPrint);
}

export function* watchGetCustomer() {
  yield takeEvery(UPDATE_SHOP_INFO, updateShopInfo);
}

export function* watchUpdateDefaultPrint() {
  yield takeEvery(UPDATE_DEFAULT_PRINT, updateDefaultPrint);
}

function* shopInfoSaga() {
  yield all([
    fork(watchGetShopInfo),
    fork(watchGetCustomer),
    fork(watchGetDefaultPrint),
    fork(watchUpdateDefaultPrint),
    fork(watchGetColorShopInfo),
    fork(watchUpdateColorShopInfo),
    fork(watchGetLanguage),
    fork(watchUpdateLanguage),
  ]);
}

export default shopInfoSaga;
