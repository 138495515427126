import React, { Fragment, useEffect, useMemo, useState, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { updateDynamicSettings, deleteDynamicSettings } from "../../../../../helpers/service_helper";
import InputTheme from "../../../../../Components/Common/InputTheme";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import SelectTheme from "../../../../../Components/Common/SelectTheme";
import { deepCopy, snakeToCapitalizedWords } from "../../../../../helpers/format_helper";
import { Alert, Card, CardBody, Col, Row, Collapse, Button, NavLink, Nav } from "reactstrap";
import SearchInput from "../../../../../Components/Common/SearchInput";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import ScrollToError from "../../../../../Components/Common/ScrollToError";
import classnames from "classnames";
import JsonView from "react18-json-view";

const DynamicSettingsForm = ({ id, initialDynamicSettings }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState({});
  const [isEditing, setIsEditing] = useState({}); // State to control editability per section
  const sectionRefs = useRef({});
  const [isBasicCollapse, setIsBasicCollapse] = useState(true);
  const [isJsonView, setIsJsonView] = useState(false);
  const [editedJson, setEditedJson] = useState("{}");
  const [newFields, setNewFields] = useState([{ key: "", value: "" }]);
  const [isAddingField, setIsAddingField] = useState(false);

  const toggleEditMode = (key) => {
    setIsEditing((prev) => ({
      ...prev,
      [key]: !prev[key], // Toggle editing mode for specific section
    }));
  };

  const handleNewFieldChange = (index, type, value) => {
    setNewFields((prev) => {
      const updated = [...prev];
      updated[index][type] = value;
      return updated;
    });
  };

  const handleNewFieldAddition = () => {
    const newField = newFields[0]; // Assuming the user enters one field at a time
    if (!newField.key || initialDynamicSettings[newField.key]) {
      toast.error("Key is either empty or already exists. Please enter a unique key.");
      return;
    }
    // Add new field to `initialDynamicSettings`
    initialDynamicSettings[newField.key] = newField.value || ""; // Default value is an empty string
    setEditedJson(JSON.stringify(initialDynamicSettings, null, 2)); // Update JSON view

    // Reset `newFields` for the next entry
    setNewFields([{ key: "", value: "" }]);
    setIsAddingField(false);

    toast.success("New field added. Please update the value as needed.");
  };

  useEffect(() => {
    if (initialDynamicSettings) {
      // Convert initial object to JSON string
      setEditedJson(JSON.stringify(initialDynamicSettings, null, 2));
    }
    console.log("initialDynamicSettings:", initialDynamicSettings);
  }, [initialDynamicSettings]);

  useEffect(() => {
    const initialCollapsedState = Object.keys(initialDynamicSettings).reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {});
    setCollapsedSections(initialCollapsedState);
  }, [initialDynamicSettings]);

  const toggleSection = (key) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
    setIsBasicCollapse((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleSubmit = async (values) => {
    try {
      const emptyFields = Object.entries(values).filter(([key, value]) => {
        return !value || value.trim() === ""; // Check for undefined, null, or empty strings
      });

      if (emptyFields.length > 0) {
        console.log("Empty fields:", emptyFields); // Debugging line to check empty fields
        emptyFields.forEach(([key]) => {
          scrollToSection(key); // Scroll and expand the section containing the empty field
        });
        toast.error("Please fill in all required fields.");
        return;
      }
      setLoading(true);
      const processedValues = Object.entries(values).reduce((acc, [key, value]) => {
        if (key === "location_mapping") {
          acc[key] = { mapping: value.mapping };
        } else if (typeof initialDynamicSettings[key] === "object" && initialDynamicSettings[key] !== null) {
          acc[key] = JSON.parse(value);
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});
      console.log("editedJson before parsing:", processedValues);
      await updateDynamicSettings(id, { dynamic_settings: processedValues });
      toast.success("Dynamic settings updated successfully!");
    } catch (e) {
      toast.error(String(e));
    } finally {
      setLoading(false);
    }
  };
  const handleSubmitJson = async () => {
    try {
      setLoading(true);

      console.log("Edited JSON before validation:", editedJson);

      let parsedJson;
      try {
        parsedJson = JSON.parse(editedJson);
      } catch (error) {
        throw new Error("Invalid JSON format. Please fix it before submitting.");
      }

      await updateDynamicSettings(id, { dynamic_settings: parsedJson });
      toast.success("Dynamic settings updated successfully!");
    } catch (e) {
      toast.error(String(e));
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (keys) => {
    if (!Array.isArray(keys) || keys.length === 0) {
      toast.error("No fields selected for deletion.");
      return;
    }

    const userConfirmed = window.confirm("Are you sure you want to delete the selected fields?");

    if (!userConfirmed) {
      toast.info("Deletion canceled.");
      return;
    }

    const payload = { fields: keys };

    try {
      setLoading(true);
      console.log("Payload sent to delete:", payload);

      await deleteDynamicSettings(id, payload);
      toast.success("Selected fields deleted successfully!");

      // Update form state by removing deleted keys
      keys.forEach((key) => {
        delete initialDynamicSettings[key];
      });
      setEditedJson(JSON.stringify(initialDynamicSettings, null, 2));
    } catch (error) {
      toast.error("Failed to delete fields. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const defaultSettings = {
    sampleField: "", // Example default field; add more as needed
  };

  const initialFormValues = useMemo(
    () =>
      Object.entries(initialDynamicSettings || defaultSettings).reduce((acc, [key, value]) => {
        if (key === "location_mapping") {
          acc[key] = { mapping: value?.mapping || {} };
        } else if (typeof value === "object" && value !== null) {
          acc[key] = JSON.stringify(value, null, 2) || "";
        } else {
          acc[key] = value || ""; // Provide an empty string as a fallback
        }
        return acc;
      }, {}),
    [initialDynamicSettings],
  );

  const validationSchema = Yup.object().shape(
    Object.entries(initialDynamicSettings).reduce((acc, [key, value]) => {
      if (key === "location_mapping") {
        acc[key] = Yup.object().shape({
          mapping: Yup.object().test("is-valid-mapping", t("Invalid mapping"), function (value) {
            return Object.values(value).every((v) => typeof v === "string" && v.trim() !== "");
          }),
        });
      } else if (typeof value === "object" && value !== null) {
        acc[key] = Yup.string().test(
          "is-valid-json",
          `${t("Invalid JSON for")} ${t(snakeToCapitalizedWords(key))}`,
          function (value) {
            if (!value) return true;
            try {
              JSON.parse(value);
              return true;
            } catch (e) {
              return false;
            }
          },
        );
      } else {
        acc[key] = Yup.string().required(`${t(snakeToCapitalizedWords(key))} ${t("is required")}`);
      }
      return acc;
    }, {}),
  );

  const scrollToSection = (key) => {
    if (sectionRefs.current[key]) {
      sectionRefs.current[key].scrollIntoView({ behavior: "smooth", block: "start" });
      if (collapsedSections[key]) {
        setCollapsedSections((prev) => ({
          ...prev,
          [key]: false, // Open the section
        }));
      }
    }
  };

  const handleJsonChange = (event) => {
    // Allow free editing in the textarea
    setEditedJson(event.target.value);
  };

  return (
    <Row className="ps-3 pe-5">
      <Col>
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <ScrollToError />
              <div
                style={{
                  position: "relative",
                  display: "inline-flex",
                  border: "3px solid #eaeaea",
                  borderRadius: "4px",
                  overflow: "hidden",
                  width: "150px",
                  height: "40px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: isJsonView ? "50%" : "0%",
                    width: "50%",
                    backgroundColor: "#e9e9ff",
                    transition: "left 0.3s ease",
                  }}
                ></div>

                <ButtonTheme
                  type="button"
                  onClick={() => setIsJsonView(false)}
                  className="btn btn-outline-primary"
                  style={{
                    padding: "8px 16px",
                    border: "none",
                    backgroundColor: "transparent",
                    fontWeight: !isJsonView ? "bold" : "normal",
                    cursor: "pointer",
                    flex: 1,
                    zIndex: 1,
                    position: "relative",
                  }}
                >
                  Fields
                </ButtonTheme>

                {/* JSON button */}
                <ButtonTheme
                  type="button"
                  onClick={() => setIsJsonView(true)}
                  className="btn btn-outline-primary"
                  style={{
                    padding: "8px 16px",
                    border: "none",
                    backgroundColor: "transparent",
                    fontWeight: isJsonView ? "bold" : "normal",
                    cursor: "pointer",
                    flex: 1,
                    zIndex: 1,
                    position: "relative",
                  }}
                >
                  JSON
                </ButtonTheme>
              </div>

              {isJsonView ? (
                <div className="mb-3 mt-3">
                  <textarea
                    value={editedJson}
                    onChange={handleJsonChange}
                    rows={10}
                    style={{ width: "100%", fontFamily: "monospace" }}
                  />
                </div>
              ) : (
                Object.entries(initialDynamicSettings).map(([key, value]) => (
                  <div key={key} ref={(el) => (sectionRefs.current[key] = el)} className="mb-3">
                    <div className="d-flex align-items-center mb-3 mt-3">
                      <ButtonTheme
                        type="button"
                        onClick={() => toggleSection(key)}
                        className="btn btn-icon me-2 border-0"
                        style={{ cursor: "pointer", padding: "5px", borderRadius: "50%" }}
                      >
                        <i
                          className={classnames(
                            "bi",
                            collapsedSections[key] ? "ri-arrow-right-s-line" : "ri-arrow-down-s-line",
                          )}
                        ></i>
                      </ButtonTheme>
                      <div className="position-relative" style={{ width: "100%" }}>
                        <input
                          type="text"
                          name={`${key}-title`}
                          value={snakeToCapitalizedWords(key)}
                          className="p-3 form-control tw-w-full tw-border tw-border-gray-300 hover:tw-border-blue-500 focus:tw-ring-1 focus:tw-ring-blue-500 hover:tw-outline-none"
                          style={{ width: "100%" }}
                          readOnly
                        />
                        <i
                          className="ri-delete-bin-5-line position-absolute text-danger"
                          style={{ right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
                          onClick={() => {
                            handleDelete([key]);
                          }}
                        ></i>
                      </div>
                    </div>
                    <Collapse isOpen={!collapsedSections[key]}>
                      {key === "location_mapping" ? (
                        <LocationMappingForm
                          location_mapping={initialDynamicSettings?.location_mapping}
                          onChangeValue={(data) => setFieldValue(`${key}.mapping`, data)}
                        />
                      ) : typeof value === "object" && value !== null ? (
                        <>
                          <div className="d-flex align-items-center">
                            <div className="ps-5 tw-ms-5 flex-grow-1">
                              <InputTheme
                                type="editJson"
                                name={key}
                                placeholder={`Enter JSON for ${key}`}
                                rows={5}
                                disabled={!isEditing[key]}
                              />
                            </div>
                            <i
                              className={`text-primary ${
                                isEditing[key] ? "ri-edit-box-fill" : "ri-edit-line"
                              } tw-text-xl tw-align-top mb-4 ms-2`}
                              onClick={() => toggleEditMode(key)}
                            ></i>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="d-flex position-relative" style={{ width: "100%" }}>
                            <div className="ms-5 ps-3 w-100">
                              <InputTheme
                                type="text"
                                name={key}
                                placeholder={`Enter value for ${key}`}
                                value={value}
                                disabled={!isEditing[key]}
                                className="p-3 form-control tw-w-full tw-border tw-border-gray-300 hover:tw-border-blue-500 focus:tw-ring-1 focus:tw-ring-blue-500 hover:tw-outline-none"
                              />
                            </div>
                            <i
                              className={`position-absolute text-primary mt-2 ${
                                isEditing[key] ? "ri-edit-box-fill" : "ri-edit-line"
                              }`}
                              style={{ right: "10px", cursor: "pointer" }}
                              onClick={() => toggleEditMode(key)}
                            ></i>
                          </div>
                        </>
                      )}
                    </Collapse>
                  </div>
                ))
              )}
              {!isAddingField ? (
                <div className="tw-ms-16 tw-me-6">
                  <ButtonTheme
                    type="button"
                    className="btn btn-outline-primary mt-2 tw-w-full"
                    onClick={() => setIsAddingField(true)}
                  >
                    <i class="ri-add-circle-line"></i>
                  </ButtonTheme>
                </div>
              ) : (
                <div className="d-flex flex-column mt-2 tw-m-24">
                  {newFields.map((field, index) => (
                    <div key={index} className="d-flex align-items-center mb-2">
                      <input
                        type="text"
                        placeholder="Enter setting name"
                        value={field.key}
                        onChange={(e) => handleNewFieldChange(index, "key", e.target.value)}
                        required
                        className="form-control me-2"
                      />
                    </div>
                  ))}

                  <div className="justify-content-between mt-2">
                    <button
                      type="button"
                      className="btn btn-primary ms-2"
                      onClick={() => {
                        handleNewFieldAddition();
                        if (newFields[0].key.trim() === "") {
                          setIsAddingField(false); // Hide form if no input
                        }
                      }}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary ms-2"
                      onClick={() => setIsAddingField(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}

              {Object.keys(initialDynamicSettings).length > 0 && !isJsonView && (
                <div className="hstack gap-2 justify-content-end mt-3">
                  <ButtonTheme
                    type="submit"
                    className="btn btn-outline-primary"
                    loadShowText={true}
                    loading={loading}
                    disabled={loading}
                  >
                    Save
                  </ButtonTheme>
                </div>
              )}
              {isJsonView && (
                <div className="hstack gap-2 justify-content-end mt-3">
                  <ButtonTheme
                    type="button"
                    className="btn btn-outline-primary"
                    loadShowText={true}
                    loading={loading}
                    disabled={loading}
                    onClick={() => handleSubmitJson(editedJson)}
                  >
                    Save
                  </ButtonTheme>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Col>
      {/* {!isJsonView && (
        <Col lg={3} className="flex-shrink-0">
          <div className="position-sticky" style={{ top: "calc(70px + 1.5rem + 60px)" }}>
            <Card>
              <Nav id="navbar-scroll-spy" className="flex-column">
                <Nav className="nav-pills flex-column p-3">
                  {Object.keys(initialDynamicSettings).map((key) => (
                    <NavLink
                      key={key}
                      href={`#${key}`}
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToSection(key);
                      }}
                      className="cursor-pointer "
                      style={{ cursor: "pointer" }}
                    >
                      <i className="ri-information-line align-middle me-2 fs-16"></i>
                      <span>{snakeToCapitalizedWords(key)}</span>
                    </NavLink>
                  ))}
                </Nav>
              </Nav>
            </Card>
          </div>
        </Col>
      )} */}
    </Row>
  );
};

const LocationMappingForm = ({ location_mapping, onChangeValue }) => {
  const { t } = useTranslation();
  const [mappings, setMappings] = useState({});
  const [externalLocations, setExternalLocations] = useState([]);
  const [masterLocations, setMasterLocations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [notification, setNotification] = useState(null);

  const handleQuickMap = () => {
    const newMappings = deepCopy(mappings);
    externalLocations.forEach((external) => {
      if (!newMappings[external.id]) {
        const match = masterLocations.find((master) => master.name.toLowerCase().includes(external.name.toLowerCase()));
        if (match) {
          newMappings[external.id] = match.id;
        }
      }
    });
    onChangeValue(newMappings);
    setNotification({ type: "success", message: "Quick mapping applied!" });
    setTimeout(() => setNotification(null), 3000);
  };

  const handleMapping = (externalId, masterId) => {
    const newMappings = deepCopy(mappings);
    if (masterId) {
      newMappings[externalId] = masterId;
    } else {
      delete newMappings[externalId];
    }
    onChangeValue(newMappings);
  };

  const filteredExternalLocations = useMemo(() => {
    return externalLocations.filter(
      (location) =>
        location.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        location.address.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [externalLocations, searchTerm]);

  useEffect(() => {
    if (location_mapping) {
      setExternalLocations(location_mapping.external || []);
      setMasterLocations(location_mapping.master || []);
      setMappings(location_mapping.mapping || {});
    }
  }, [location_mapping]);

  return (
    <Fragment>
      <Row className="mb-4">
        <Col lg={12} className="d-flex gap-2">
          <div className="flex-grow-1">
            <SearchInput
              onChangeValue={(value) => {
                setSearchTerm(value);
              }}
              searchValue={searchTerm}
              onDeleteSearchValue={() => setSearchTerm("")}
              placeholder={"Search branches..."}
            />
          </div>
          <ButtonTheme onClick={handleQuickMap} className="btn btn-outline-primary">
            <i className="ri-flashlight-line me-2" />
            Quick Map
          </ButtonTheme>
        </Col>
      </Row>
      {notification && (
        <Alert color={notification.type === "success" ? "success" : "danger"} className="mb-4">
          {notification.message}
        </Alert>
      )}
      <div className="px-4">
        {" "}
        {/* Removed SimpleBar here */}
        {filteredExternalLocations.map((location) => (
          <Card className="mb-4" key={location.id}>
            <CardBody className="shadow-lg">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h5 className="mb-0">{location.name}</h5>
                <i className="ri-map-2-line text-gray-500 fs-20" />
              </div>
              <p className="small text-muted mb-2">{location.address}</p>
              <p className="small text-muted mb-3">
                {location.cityName}, {location.districtName}, {location.wardName}
              </p>
              <SelectTheme
                placeholder="Select branch"
                isForm={false}
                options={masterLocations}
                value={mappings[location.id] || ""}
                onChange={(value) => {
                  handleMapping(location.id, value?.value);
                }}
              />
            </CardBody>
          </Card>
        ))}
      </div>
    </Fragment>
  );
};

export default DynamicSettingsForm;
