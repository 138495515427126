import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  getInventoryItem,
  getInventoryItemTransaction,
  listInventoryItem,
} from "../../../../../store/ecommerce/inventory/inventory_items/action";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import SimpleBar from "simplebar-react";
import { isEmpty } from "lodash";

import { convertValidDate, convertValidTime, deepCopy } from "../../../../../helpers/format_helper";

import PriceDetails from "../Components/PriceDetails";
import VariantDetails from "../Components/VariantDetails";
import ProductDetails from "../Components/ProductDetails";
import Loader from "../../../../../Components/Common/Loader";
import SubmitModal from "../../../../../Components/Common/SubmitModal";
import withRouter from "../../../../../Components/Common/withRouter";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import BrandsModal from "../Components/BrandsModal";
import BreadCrumbCustom from "../../../../../Components/Common/BreadCrumbCustom";
import CategoriesModal from "../Components/CategoriesModal";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../../Components/Common/TableContainerCustom";
import { Actions, TransactionType } from "../../../../../Components/constants/common";

import { listPriceGroup, getProduct, deleteProduct } from "../../../../../store/actions";
import { Tooltip } from "antd";
import ScrollToError from "../../../../../Components/Common/ScrollToError";
import { toast } from "react-toastify";
import { syncToHRV } from "../../../../../helpers/service_helper";
import SyncComponent from "../../../../../Components/Common/SynceComponent";

const EditProductPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Inventory Details")} | OptiWarehouse`;
  const { id } = params;
  const sku = location.state?.sku || "";
  const dispatch = useDispatch();

  const formRef = useRef();

  const { productState, productsAction, productsLoading, productsSuccess, productsMsg, productsError } =
    useMemoizedSelector((state) => ({
      productState: state.Products.product,
      productsLoading: state.Products.loading,
      productsAction: state.Products.action,
      productsSuccess: state.Products.success,
      productsError: state.Products.error,
      productsMsg: state.Products.message,
    }));
  const { loadingVersion, version } = useMemoizedSelector((state) => ({
    loadingVersion: state.Version.loading,
    version: state.Version.version,
  }));
  const { priceGroups, priceGroupsLoading, priceGroupsSuccess, priceGroupsError } = useMemoizedSelector((state) => ({
    priceGroups: state.PriceGroups.priceGroups,
    priceGroupsLoading: state.PriceGroups.loading,
    priceGroupsSuccess: state.PriceGroups.success,
    priceGroupsError: state.PriceGroups.error,
  }));

  const {
    inventoryItem,
    inventoryItemLoading,
    inventoryItemSuccess,
    inventoryItemError,
    inventoryItemLimit,
    inventoryItemPage,
    inventoryItemTotal,
    inventoryItemTransaction,
    inventoryItemTransactionLoading,
    inventoryItemTransactionSuccess,
    inventoryItemTransactionError,
    inventoryItemTransactionLimit,
    inventoryItemTransactionPage,
    inventoryItemTransactionTotal,
  } = useMemoizedSelector((state) => ({
    inventoryItem: state.InventoryItems.inventoryItem,
    inventoryItemLoading: state.InventoryItems.loadingInventoryItem,
    inventoryItemSuccess: state.InventoryItems.successInventoryItem,
    inventoryItemError: state.InventoryItems.errorInventoryItem,
    inventoryItemLimit: state.InventoryItems.limitInventoryItem,
    inventoryItemTotal: state.InventoryItems.totalInventoryItem,
    inventoryItemPage: state.InventoryItems.pageInventoryItem,
    inventoryItemTransaction: state.InventoryItems.inventoryItemTransaction,
    inventoryItemTransactionLoading: state.InventoryItems.loadingInventoryItemTransaction,
    inventoryItemTransactionSuccess: state.InventoryItems.successInventoryItemTransaction,
    inventoryItemTransactionError: state.InventoryItems.errorInventoryItemTransaction,
    inventoryItemTransactionLimit: state.InventoryItems.limitInventoryItemTransaction,
    inventoryItemTransactionTotal: state.InventoryItems.totalInventoryItemTransaction,
    inventoryItemTransactionPage: state.InventoryItems.pageInventoryItemTransaction,
  }));

  const [activeTab, setActiveTab] = useState("1");
  const [verticalTab, setVerticalTab] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedPriceApply, setSelectedPriceApply] = useState(null);
  const [selectedMeasurementApply, setSelectedMeasurementApply] = useState(null);

  const [submitMeasurementModal, setSubmitMeasurementModal] = useState(false);
  const [isOpenCategoriesModal, setIsOpenCategoriesModal] = useState(false);
  const [isOpenBrandsModal, setIsOpenBrandsModal] = useState(false);
  const [submitPriceModal, setSubmitPriceModal] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);

  const validation = {
    enableReinitialize: true,
    initialValues: {
      brand: productState?.brand ?? "",
      images: productState?.images ?? [],
      category: productState?.category ?? "",
      description: productState?.description ?? "",
      name: productState?.name ?? "",
      prices: productState?.prices ?? null,
      publish: productState?.publish ?? false,
      shortDescription: productState?.shortDescription ?? null,
      sku: productState?.sku ?? "",
      tags: productState?.tags ?? null,
      measurements: productState?.measurements ?? {
        height_unit: productState?.measurements?.height_unit ?? "cm",
        height_value: productState?.measurements?.height_value ?? 0,
        length_unit: productState?.measurements?.length_unit ?? "cm",
        length_value: productState?.measurements?.length_value ?? 0,
        width_unit: productState?.measurements?.width_unit ?? "cm",
        width_value: productState?.measurements?.width_value ?? 0,
        weight_unit: productState?.measurements?.weight_unit ?? "g",
        weight_value: productState?.measurements?.weight_value ?? 0,
      },
      variants: productState?.variants ?? [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Please Enter Product Name")),
      sku: Yup.string().required(t("Please Enter Product Sku")),
    }),
  };

  const handleChangeName = (data) => {
    const newName = data.target.value;

    if (formRef.current?.values.variants && formRef.current?.values.variants.length > 0) {
      const newVariants = formRef.current?.values.variants.map((item) => {
        item.unit && (item.name = `${item.name} - (${item.unit.name})`);
        item.option1 && (item.name = newName + " - " + item.option1);
        item.option2 && (item.name = item.name + " - " + item.option2);
        item.option3 && (item.name = item.name + " - " + item.option3);
        return item;
      });
      formRef.current?.setFieldValue("variants", newVariants);
    }
  };

  const handleChangeImages = (data) => {
    formRef.current?.setFieldValue("images", data);
  };

  const handleChangeVariant = (data, index) => {
    let newVariants = deepCopy(formRef.current?.values.variants);
    newVariants[index] = data;
    formRef.current?.setFieldValue("variants", newVariants);
  };

  const handleApplyAllPrices = () => {
    let newVariants = formRef.current?.values.variants.map((item) => {
      item.prices = selectedPriceApply;
      return item;
    });
    formRef.current?.setFieldValue("variants", newVariants);
    toggleOffSubmitPriceModal();
  };

  const handleApplyAllMeasurement = () => {
    let newVariants = formRef.current?.values.variants.map((item) => {
      item.measurements = selectedMeasurementApply;
      return item;
    });
    formRef.current?.setFieldValue("variants", newVariants);
    toggleOffSubmitMeasurementModal();
  };

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleVertical = (tab) => {
    if (verticalTab !== tab) {
      setVerticalTab(tab);
    }
  };
  const toggleOffCategoriesModal = () => {
    setIsOpenCategoriesModal(false);
  };
  const toggleOffBrandsModal = () => {
    setIsOpenBrandsModal(false);
  };

  const toggleOnCategoriesModal = () => {
    setIsOpenCategoriesModal(true);
  };
  const toggleOnBrandsModal = () => {
    setIsOpenBrandsModal(true);
  };

  const toggleOffSubmitPriceModal = () => {
    setSubmitPriceModal(false);
    setSelectedPriceApply(null);
  };
  const toggleOffSubmitMeasurementModal = () => {
    setSubmitMeasurementModal(false);
    setSelectedMeasurementApply(null);
  };
  const toggleOnSubmitPriceModal = (data) => {
    setSubmitPriceModal(true);
    setSelectedPriceApply(data);
  };
  const toggleOnSubmitMeasurementModal = (data) => {
    setSubmitMeasurementModal(true);
    setSelectedMeasurementApply(data);
  };

  const handleSync = async () => {
    try {
      setSyncLoading(true);
      await syncToHRV(id);
      toast.success(t("Sync successfully!"));
    } catch (e) {
      toast.error(e);
    } finally {
      setSyncLoading(false);
    }
  };

  const inventoryItemHeaders = [
    {
      title: t("Location"),
      dataIndex: "location_id",
      key: "location_id",
      render: (value, row, index) => {
        let location = (version?.data?.list?.location ?? []).find((item) => item.id === value);
        return <>{t(location?.name || "---")}</>;
      },
    },
    {
      title: t("Available"),
      dataIndex: "available",
      key: "available",
    },
    {
      title: t("Incoming"),
      dataIndex: "incoming",
      key: "incoming",
    },
    {
      title: t("On hand"),
      dataIndex: "on_hand",
      key: "on_hand",
    },
    {
      title: t("Packing"),
      dataIndex: "packing",
      key: "packing",
    },
    {
      title: t("Shipping"),
      dataIndex: "shipping",
      key: "shipping",
    },
    {
      title: t("Min value"),
      dataIndex: "min_value",
      key: "min_value",
    },
    {
      title: t("Max value"),
      dataIndex: "max_value",
      key: "max_value",
    },
  ];

  const handleTransactionType = (data) => {
    const pathMap = {
      [TransactionType.ADJUSTMENT]: "stock-adjustments",
      [TransactionType.RELOCATE]: "stock-relocates",
      [TransactionType.ORDER]: "orders",
      [TransactionType.PACKAGE]: "packages",
      [TransactionType.PURCHASE]: "purchase-orders",
      [TransactionType.RETURN]: "return-orders",
    };

    return pathMap[data] || "";
  };

  const transactionHeaders = [
    {
      title: t("Created at"),
      dataIndex: "created_at",
      defaultSortOrder: "desc",
      key: "created_at",
      sorter: true,
      render: (value, row, index) => {
        return (
          <>
            {convertValidDate(value)} {convertValidTime(value)}
          </>
        );
      },
    },
    {
      title: t("Staff"),
      dataIndex: "staff_id",
      key: "staff_id",
      render: (value, row, index) => {
        return (
          <Tooltip title={value}>
            <div className="text-truncate-two-lines">{value}</div>
          </Tooltip>
        );
      },
    },
    {
      title: t("Transaction type"),
      dataIndex: "transaction_type",
      key: "transaction_type",
    },
    {
      title: t("Change"),
      dataIndex: "change",
      key: "change",
      render: (value, row, index) => {
        return <>{value}</>;
      },
    },
    {
      title: t("Quantity"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: t("Reference"),
      dataIndex: "reference",
      key: "reference",
      render: (value, row, index) => {
        return (
          <Tooltip title={value}>
            <Link
              to={`/${handleTransactionType(row.transaction_type)}/${value}`}
              className="link-primary text-truncate-two-lines"
            >
              {value}
            </Link>
          </Tooltip>
        );
      },
    },
    {
      title: t("Location"),
      dataIndex: "location_id",
      key: "location_id",
      render: (value, row, index) => {
        let location = (version?.data?.list?.location ?? []).find((item) => item.id === value);
        return <>{t(location?.name || "---")}</>;
      },
    },
  ];

  useEffect(() => {
    dispatch(getProduct(id));
    dispatch(listPriceGroup({ limit: 100 }));
  }, [dispatch]);

  useEffect(() => {
    let data = sku
      ? sku
      : productState.variants && productState.variants.length > 0
      ? productState.variants[0].sku
      : productState.sku;
    setVerticalTab(data);
  }, [productState]);

  useEffect(() => {
    setSelectedLocation(version?.data?.list?.location?.[0]?.id);
  }, [version]);

  useEffect(() => {
    if (!isEmpty(verticalTab)) {
      dispatch(getInventoryItem({ id: verticalTab, sort_created_at: "desc" }));
    }
  }, [verticalTab]);

  useEffect(() => {
    if (!isEmpty(verticalTab)) {
      if (!loadingVersion && selectedLocation) {
        dispatch(
          getInventoryItemTransaction({
            location_id: selectedLocation,
            sku: verticalTab,
            sort_created_at: "desc",
          }),
        );
      }
    }
  }, [selectedLocation, loadingVersion, verticalTab]);

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumbCustom text="Back to product list" navigateTo={sku ? "/variants" : "/products"}>
            <div className="hstack gap-2">
              <ButtonTheme
                type="button"
                className="btn btn-soft-danger w-md"
                onClick={() => {
                  dispatch(deleteProduct(productState, () => navigate(`/products`)));
                }}
                loading={productsAction === Actions.DELETE && productsLoading}
                disabled={productsLoading || syncLoading}
              >
                {t("Delete")}
              </ButtonTheme>
              <ButtonTheme
                type="button"
                className="btn btn-primary w-md"
                onClick={() => {
                  navigate(`/products/${id}/edit`);
                }}
                disabled={productsLoading || syncLoading}
              >
                {t("Edit product")}
              </ButtonTheme>
              {/* <ButtonTheme
                type="button"
                className="btn btn-soft-success w-md"
                onClick={handleSync}
                loading={syncLoading}
                disabled={syncLoading || productsLoading}
              >
                {t("Sync to HRV")}
              </ButtonTheme> */}
            </div>
          </BreadCrumbCustom>
          <CategoriesModal isOpen={isOpenCategoriesModal} toggle={toggleOffCategoriesModal} />
          <BrandsModal isOpen={isOpenBrandsModal} toggle={toggleOffBrandsModal} />
          <SubmitModal
            show={submitPriceModal}
            onCloseClick={toggleOffSubmitPriceModal}
            onSubmitClick={handleApplyAllPrices}
            text={"Do you want to apply prices for all variants?"}
          />
          <SubmitModal
            show={submitMeasurementModal}
            onCloseClick={toggleOffSubmitMeasurementModal}
            onSubmitClick={handleApplyAllMeasurement}
            text={"Do you want to apply measurement for all variants?"}
          />
          {productsAction === Actions.GET ? (
            <Loader />
          ) : (
            <Formik
              enableReinitialize={true}
              initialValues={validation.initialValues}
              validationSchema={validation.validationSchema}
              onSubmit={validation.onSubmit}
              innerRef={formRef}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <ScrollToError />
                  <Row>
                    <Col lg={12}>
                      <ProductDetails
                        productState={productState}
                        values={values}
                        setFieldValue={setFieldValue}
                        handleChangeImages={handleChangeImages}
                        handleChangeName={handleChangeName}
                        toggleOnBrandsModal={toggleOnBrandsModal}
                        toggleOnCategoriesModal={toggleOnCategoriesModal}
                      />
                    </Col>
                    {productState?.sync_record_id && (
                      <Col lg={12}>
                        <SyncComponent sync_record_id={productState?.sync_record_id} />
                      </Col>
                    )}
                    <Col lg={3}>
                      <Card className="position-sticky" style={{ top: "calc(70px + 1.5rem + 60px)" }}>
                        <CardHeader>
                          <Row className="g-4 align-items-center">
                            <div className="col-sm">
                              <div>
                                <h5 className="card-title mb-0">{t("Variants")}</h5>
                              </div>
                            </div>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <SimpleBar style={{ maxHeight: "calc(100vh - 70px - 1.5rem - 60px)" }}>
                            <Nav pills className="flex-column nav-light" id="v-pills-tab">
                              {((values.variants || []).length > 0 ? values.variants : [values]).map((variant, key) => (
                                <NavItem key={key} id={variant.sku}>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      "mb-2": true,
                                      active: verticalTab === variant.sku,
                                    })}
                                    onClick={() => {
                                      toggleVertical(variant.sku);
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      {variant?.unit?.id && <i className="ri-corner-down-right-line me-3" />}
                                      <div className="flex-shrink-0 me-3">
                                        <div className="bg-light rounded p-1">
                                          <img
                                            src={variant?.images?.[0]?.url}
                                            alt=""
                                            className="avatar-sm"
                                            style={{ objectFit: "contain" }}
                                          />
                                        </div>
                                      </div>
                                      <div className="flex-grow-1">
                                        <Tooltip title={variant?.name}>
                                          <p className="p-0 text-truncate-two-lines">{t(variant?.name || "---")}</p>
                                        </Tooltip>
                                        <Tooltip title={variant?.sku}>
                                          <span className="text-muted text-truncate-two-lines">{variant.sku}</span>
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </NavLink>
                                </NavItem>
                              ))}
                            </Nav>
                          </SimpleBar>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={9}>
                      <TabContent activeTab={verticalTab} className="mt-4 mt-md-0" id="v-pills-tabContent">
                        {(values.variants || []).map((variant, key) => (
                          <TabPane tabId={variant.sku} key={key}>
                            <VariantDetails
                              validation={formRef.current}
                              variant={variant}
                              onChangeVariant={handleChangeVariant}
                              index={key}
                              toggleOnSubmitMeasurementModal={toggleOnSubmitMeasurementModal}
                              productState={productState}
                            />
                            <PriceDetails
                              validation={formRef.current}
                              variant={variant}
                              onChangeVariant={handleChangeVariant}
                              index={key}
                              priceGroups={priceGroups}
                              toggleOffSubmitPriceModal={toggleOnSubmitPriceModal}
                            />
                          </TabPane>
                        ))}
                      </TabContent>
                      <Card>
                        <CardHeader>
                          <Nav
                            className="d-flex nav-tabs-custom nav-primary rounded card-header-tabs border-bottom-0"
                            role="tablist"
                          >
                            <NavItem>
                              <NavLink
                                className={classnames({ active: activeTab === "1" })}
                                onClick={() => {
                                  tabChange("1");
                                }}
                              >
                                {t("Inventory")}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                to="#"
                                className={classnames({ active: activeTab === "2" })}
                                onClick={() => {
                                  tabChange("2");
                                }}
                                type="button"
                              >
                                {t("Inventory History")}
                              </NavLink>
                            </NavItem>
                            {activeTab === "2" && (
                              <NavItem className="hstack flex-grow-1 justify-content-end">
                                <div>
                                  <Input
                                    type="select"
                                    className="form-select w-sm"
                                    value={selectedLocation}
                                    onChange={(e) => {
                                      setSelectedLocation(e.target.value);
                                    }}
                                  >
                                    {(version?.data?.list?.location ?? []).map((item, key) => (
                                      <option key={key} value={item.id}>
                                        {t(item.name || "---")}
                                      </option>
                                    ))}
                                  </Input>
                                </div>
                              </NavItem>
                            )}
                          </Nav>
                        </CardHeader>
                        <CardBody>
                          <TabContent activeTab={activeTab}>
                            <TabPane tabId={"1"}>
                              <TableContainerCustom
                                headers={inventoryItemHeaders}
                                contents={inventoryItem}
                                limitPage={Number(inventoryItemLimit)}
                                currentPage={Number(inventoryItemPage) + 1}
                                totalItems={inventoryItemTotal}
                                loading={inventoryItemLoading}
                                keyRow="created_at"
                                scrollHeight="50vh"
                                onCallData={(dataPayload) => {
                                  dispatch(listInventoryItem({ id: verticalTab, ...dataPayload }));
                                }}
                              />
                            </TabPane>
                            <TabPane tabId={"2"}>
                              <TableContainerCustom
                                headers={transactionHeaders}
                                contents={inventoryItemTransaction}
                                limitPage={Number(inventoryItemTransactionLimit)}
                                currentPage={Number(inventoryItemTransactionPage) + 1}
                                totalItems={inventoryItemTransactionTotal}
                                loading={inventoryItemTransactionLoading}
                                keyRow="created_at"
                                scrollHeight="50vh"
                                onCallData={(dataPayload) => {
                                  dispatch(
                                    getInventoryItemTransaction({
                                      location_id: selectedLocation,
                                      sku: verticalTab,
                                      ...dataPayload,
                                    }),
                                  );
                                }}
                              />
                            </TabPane>
                          </TabContent>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          )}
        </Container>
      </div>
    </Fragment>
  );
};
export default withRouter(EditProductPage);
