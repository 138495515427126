import {
  LIST_BLOG,
  DELETE_BLOG,
  DELETE_BLOG_SUCCESS,
  DELETE_BLOG_FAIL,
  ADD_NEW_BLOG,
  ADD_BLOG_SUCCESS,
  ADD_BLOG_FAIL,
  UPDATE_BLOG,
  UPDATE_BLOG_SUCCESS,
  UPDATE_BLOG_FAIL,
  LIST_BLOG_FAIL,
  LIST_BLOG_SUCCESS,
  GET_BLOG,
  GET_BLOG_FAIL,
  GET_BLOG_SUCCESS,
  RESET_BLOG_STATE,
} from "./actionType";

export const listBlog = (options) => ({
  type: LIST_BLOG,
  payload: options,
});

export const listBlogFail = (error) => ({
  type: LIST_BLOG_FAIL,
  payload: error,
});

export const listBlogSuccess = (blogs) => ({
  type: LIST_BLOG_SUCCESS,
  payload: blogs,
});

export const getBlog = (blogID, isCheckBlog) => ({
  type: GET_BLOG,
  payload: { blogID, isCheckBlog },
});

export const getBlogFail = (error) => ({
  type: GET_BLOG_FAIL,
  payload: error,
});

export const getBlogSuccess = (blog) => ({
  type: GET_BLOG_SUCCESS,
  payload: blog,
});

export const deleteBlog = (blog, handleAfterSubmit = () => {}) => ({
  type: DELETE_BLOG,
  payload: { blog, handleAfterSubmit },
});

export const deleteBlogSuccess = (blog) => ({
  type: DELETE_BLOG_SUCCESS,
  payload: blog,
});

export const deleteBlogFail = (error) => ({
  type: DELETE_BLOG_FAIL,
  payload: error,
});

export const updateBlog = (id, blog, handleAfterSubmit = () => {}) => ({
  type: UPDATE_BLOG,
  payload: { id, blog, handleAfterSubmit },
});

export const updateBlogSuccess = (blog) => ({
  type: UPDATE_BLOG_SUCCESS,
  payload: blog,
});

export const updateBlogFail = (error) => ({
  type: UPDATE_BLOG_FAIL,
  payload: error,
});

export const addNewBlog = (blog, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_BLOG,
  payload: { blog, handleAfterSubmit },
});

export const addBlogSuccess = (blog) => ({
  type: ADD_BLOG_SUCCESS,
  payload: blog,
});

export const addBlogFail = (error) => ({
  type: ADD_BLOG_FAIL,
  payload: error,
});

export const resetBlogState = () => ({
  type: RESET_BLOG_STATE,
});
