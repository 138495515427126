import React, { useEffect, useState, useRef, Fragment, useImperativeHandle } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
//formik
import * as Yup from "yup";
import { Form, Formik } from "formik";
// Import React FilePond
import { registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
// Import FilePond styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";

import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { deepCopy } from "../../../../helpers/format_helper";
import InputTheme from "../../../../Components/Common/InputTheme";
import SelectTheme from "../../../../Components/Common/SelectTheme";
import UploadTheme from "../../../../Components/Common/UploadTheme";
import { Popover } from "antd";
import CKEditorCustom from "../../../../Components/Common/CKEditorCustom";
import withRouter from "../../../../Components/Common/withRouter";
import ScrollToError from "../../../../Components/Common/ScrollToError";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const FormBlogOrigin = (props) => {
  const { router, formRef, dataDefault, onHandleAfterSubmit = () => {} } = props;
  const { navigate, location, params, t, state } = router;
  const dispatch = useDispatch();
  const { id } = params;
  // document.title = `${t("View Blog")} | OptiWarehouse`;

  // const { blogState, blogsLoading, blogsSuccess, blogsAction, blogsMsg } = useMemoizedSelector((state) => ({
  //   blogState: state.Blogs.blog,
  //   blogsLoading: state.Blogs.loading,
  //   blogsAction: state.Blogs.action,
  //   blogsSuccess: state.Blogs.success,
  //   blogsError: state.Blogs.error,
  //   blogsMsg: state.Blogs.message,
  // }));

  const { version } = useMemoizedSelector((state) => ({
    version: state.Version.version,
  }));

  const refForm = useRef();
  useImperativeHandle(formRef, () => ({
    onSubmitForm: async () => {
      await refForm?.current?.submitForm();
      const checkValidation = await refForm.current.isValid;
      const values = refForm.current.values;
      if (!values.tags) {
        values.meta_data && (values.meta_data.keywords = null);
      } else {
        values.meta_data && (values.meta_data.keywords = values.tags);
      }
      if (checkValidation) {
        return values;
      } else {
        // throw new ('Form không hợp lệ')();
        return null;
      }
    },
  }));

  const validation = {
    enableReinitialize: true,
    initialValues: {
      image: dataDefault?.image ?? null,
      title: dataDefault?.title ?? "",
      content: dataDefault?.content ?? null,
      description: dataDefault?.description ?? "",
      tags: dataDefault?.tags ?? null,
      category: dataDefault?.category ?? null,
      slug: dataDefault?.slug ?? null,
      meta_data: dataDefault?.meta_data ?? null,
      blog_type: dataDefault?.blog_type ?? "blog",
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t("Please Enter Blog Title")),
      content: Yup.string().required(t("Please Enter Blog Content")),
    }),
    onSubmit: async (values) => {
      onHandleAfterSubmit(values);
    },
  };

  const handleChangeMetaData = (field, value, meta_data) => {
    const metaData = deepCopy(meta_data);
    if (value) {
      metaData[field] = value;
    } else {
      delete metaData[field];
    }
    refForm?.current.setFieldValue("meta_data", metaData);
  };

  return (
    <div>
      {/* <Container fluid>
       
        <BreadCrumbCustom text="Back to blog list" navigateTo="/blogs" t={t}>
          <div className="hstack gap-2">
            {id && (
              
            )}
            <ButtonTheme
              type="button"
              className="btn btn-outline-primary w-sm"
              onClick={() => formRef.current.handleSubmit()}
              loading={(blogsAction === Actions.UPDATE || blogsAction === Actions.CREATE) && blogsLoading}
              disabled={(blogsAction === Actions.UPDATE || blogsAction === Actions.CREATE) && blogsLoading}
            >
              {t(id ? "Update" : "Create")}
            </ButtonTheme>
          </div>
        </BreadCrumbCustom> */}

      <Formik
        enableReinitialize={true}
        initialValues={validation.initialValues}
        validationSchema={validation.validationSchema}
        onSubmit={validation.onSubmit}
        innerRef={refForm}
      >
        {({ values, setFieldValue, errors, touched }) => {
          // const link = version?.data?.dict?.shop_info?.setting_value?.store_url;
          // const current_link = `${link}/blogs/${blog?.slug}`;
          return (
            <Form>
              {/* <ScrollToError /> */}
              <div className="d-flex gap-3">
                <div className="flex-grow-1">
                  <Card id="basic-information">
                    <CardBody>
                      {/* {link && blog?.slug && (
                          <div className="mb-3">
                            {t("Link")}: <CopyableText text={current_link}>{current_link}</CopyableText>
                          </div>
                        )} */}
                      <div id="blog-images" className="hstack gap-5 mb-3">
                        <div className="flex-1 col-6">
                          <InputTheme name={"title"} placeholder="Enter blog title" label={"Title"} />
                          <SelectTheme
                            name="category"
                            placeholder={"Select blog category"}
                            remote={true}
                            selectDataOrigin={true}
                            isObjectValueSelect={true}
                            path="blog/categories"
                            label={t("Category")}
                          />
                          <SelectTheme
                            name="blog_type"
                            placeholder={"Select type blog"}
                            label={t("Type")}
                            options={[
                              { label: "Blog", value: "blog" },
                              { label: "Page", value: "page" },
                            ]}
                          />
                        </div>
                        <UploadTheme label="Image" name="image" />
                      </div>
                      <InputTheme name={"description"} placeholder="Enter blog description" label={"Description"} />

                      <div id="blog-content" className="mb-3">
                        <h5 className="fs-14 mb-1 hstack justify-content-between">
                          {t("Content")}
                          <Popover
                            content={
                              <div style={{ width: "850px", height: "400px", overflow: "auto" }}>
                                {values?.content ? (
                                  <div className="ck-content" dangerouslySetInnerHTML={{ __html: values?.content }} />
                                ) : (
                                  ""
                                )}
                              </div>
                            }
                            title={t("Preview")}
                            placement="right"
                          >
                            <div className="hstack gap-2">
                              <span>
                                <i className="ri-eye-fill align-bottom color-sub-text-secondary fs-18" />
                              </span>
                            </div>
                          </Popover>
                        </h5>
                        <div
                          style={{
                            border: `1px solid ${errors.description && touched.description ? "#f06548" : "#d1d1d1"}`,
                            backgroundColor: "#fff",
                          }}
                        >
                          <CKEditorCustom
                            config={{
                              language: "vi",
                              resize: {
                                minHeight: "300px", // Set minimum height
                                maxHeight: "600px", // Set maximum height
                              },
                            }}
                            imagePrefix="blogs"
                            value={values?.content}
                            onChangeValue={(data) => {
                              setFieldValue("content", data);
                            }}
                          />
                        </div>
                        {errors.content && touched.content && (
                          <div className="position-absolute fs-10" style={{ color: "#f06548" }}>
                            {errors.content}
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <h5 className="card-title">{t("SEO")}</h5>
                      <div className="mb-3">
                        <InputTheme name={"slug"} placeholder={"this-is-blog-tile"} label={"Slug"} />
                      </div>
                      <div className="mb-3">
                        <InputTheme
                          id="title_tag"
                          isForm={false}
                          placeholder="Enter Title Tag"
                          label={"Title Tag"}
                          value={values.meta_data?.title}
                          onChange={(value) => {
                            handleChangeMetaData("title", value, values.meta_data || {});
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <InputTheme
                          id="description_tag"
                          isForm={false}
                          placeholder="Enter Description Tag"
                          label={"Description Tag"}
                          value={values.meta_data?.description}
                          onChange={(value) => {
                            handleChangeMetaData("description", value, values.meta_data || {});
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <InputTheme
                          name={"tags"}
                          label={"Keywords Tag"}
                          type="tags"
                          placeholder={"Enter keywords and press enter"}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default withRouter(FormBlogOrigin);
