import React from "react";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import ButtonTheme from "../../Components/Common/ButtonTheme";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Settings() {
  const { t } = useTranslation();
  document.title = `${t("Settings")} | OptiWarehouse`;

  const StoreSetups = [
    {
      title: "Store information",
      description: "Manage contact information and store addresses",
      icon: "ri-store-3-fill",
      to: "shop-info",
    },
    {
      title: "Price group",
      description: "Create and manage the pricing policies of the store",
      icon: "ri-money-dollar-circle-line",
      to: "price-groups",
    },
    {
      title: "Unit",
      description: "Create and manage the unit of variants",
      icon: "ri-instance-line",
      to: "units",
    },
    {
      title: "Print template",
      description: "Set up & customize print templates by branch.",
      icon: "ri-printer-fill",
      to: "prints",
    },
    {
      title: "Employees and Permissions",
      description: "Manage & delegate employee accounts",
      icon: "ri-user-settings-fill",
      to: "accounts",
    },
    {
      title: "Theme color",
      description: "Manage theme color for your webapp",
      icon: "ri-palette-fill",
      to: "colors",
    },
    // {
    //   title: "Language setting",
    //   description: "Change your language for your webapp",
    //   icon: "ri-global-fill",
    //   to: "language",
    // },
  ];
  const POSSetups = [];
  const Logs = [
    {
      title: "Activity log",
      description: "Manage operations and activity logs of the store",
      icon: "ri-history-fill",
      to: "activities",
    },
  ];
  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          <CardHeader>
            <h5 className="card-title flex-grow-1 mb-0">{t("Store setups")}</h5>
          </CardHeader>
          <CardBody className="d-flex flex-wrap gap-3">
            {StoreSetups.map((item, index) => (
              <BoxSetting key={index} {...item} />
            ))}
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h5 className="card-title flex-grow-1 mb-0">{t("Sales setups")}</h5>
          </CardHeader>
          <CardBody className="d-flex flex-wrap gap-3">
            {POSSetups.map((item, index) => (
              <BoxSetting key={index} {...item} />
            ))}
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h5 className="card-title flex-grow-1 mb-0">{t("Logs")}</h5>
          </CardHeader>
          <CardBody className="d-flex flex-wrap gap-3">
            {Logs.map((item, index) => (
              <BoxSetting key={index} {...item} />
            ))}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

const BoxSetting = ({ title, description, icon = "", to, img = "" }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <ButtonTheme
      style={{ height: 100 }}
      className="btn btn-outline-primary"
      onClick={() => {
        navigate(to);
      }}
    >
      <div className="hstack">
        {icon && <i className={`${icon} fs-36`}></i>}
        {img && <img src={img} alt="theme" />}
        <div className="mx-2" style={{ maxWidth: "200px" }}>
          <p className="fs-18 text-start text-truncate">{t(title)}</p>
          <p className="text-start fs-13 text-truncate-two-lines">{t(description)}</p>
        </div>
      </div>
    </ButtonTheme>
  );
};
