import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ButtonTheme from "../../../Components/Common/ButtonTheme";
import { Card, CardBody, CardTitle, Button, Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useTranslation } from "react-i18next";
import { updateLanguage, getLanguage } from "../../../store/actions";
import languages from "../../../common/languages";
import { get } from "lodash";

const allLanguages = [
  { code: "en", name: "English" },
  { code: "es", name: "Spanish" },
  { code: "fr", name: "French" },
  { code: "de", name: "German" },
  { code: "it", name: "Italian" },
  { code: "pt", name: "Portuguese" },
  { code: "ru", name: "Russian" },
  { code: "china", name: "Chinese" },
  { code: "jp", name: "Japanese" },
  { code: "kr", name: "Korean" },
  { code: "vn", name: "Việt nam" },
];

export default function LanguageSettings() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Fetch initial languages from Redux
  const { language: reduxLanguages, loading } = useSelector((state) => state.ShopInfo);

  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLanguages, setFilteredLanguages] = useState([]);

  useEffect(() => {
    // Fetch languages from the Redux store
    dispatch(getLanguage());
  }, [dispatch]);

  useEffect(() => {
    if (reduxLanguages && Array.isArray(reduxLanguages)) {
      setSupportedLanguages(reduxLanguages); // Set languages from Redux as initial state
    }
  }, [reduxLanguages]);

  useEffect(() => {
    if (searchTerm) {
      setFilteredLanguages(
        allLanguages.filter(
          (lang) =>
            lang.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            !supportedLanguages.some((supported) => supported.code === lang.code),
        ),
      );
    } else {
      setFilteredLanguages([]);
    }
  }, [searchTerm, supportedLanguages]);

  const handleSaveLanguages = () => {
    dispatch(
      updateLanguage(
        supportedLanguages.map((lang) => ({ code: lang.code, name: lang.name })),
        () => {
          dispatch(getLanguage());
        },
      ),
    );
  };

  const handleAddLanguage = (language) => {
    console.log("sdfsd", language);
    if (!supportedLanguages.some((lang) => lang.code === language.code)) {
      const updatedLanguages = [...supportedLanguages, language];
      setSupportedLanguages(updatedLanguages);

      dispatch(
        updateLanguage(updatedLanguages, () => {
          dispatch(getLanguage());
        }),
      );
    }
    setSearchTerm(""); // Clear search term
  };

  const handleRemoveLanguage = (code) => {
    const updatedLanguages = supportedLanguages.filter((lang) => lang.code !== code);
    setSupportedLanguages(updatedLanguages);

    dispatch(
      updateLanguage(updatedLanguages, () => {
        dispatch(getLanguage());
      }),
    );
  };

  return (
    <div className="page-content">
      <Container>
        <BreadCrumb title={t("Language settings")} pageTitle="Settings" navigateTo="/settings" isBack={true} />
        <Card className="mt-4 mx-auto" style={{ maxWidth: "100%" }}>
          <CardBody>
            <CardTitle tag="h2" className="text-center mb-4">
              Language Settings
            </CardTitle>

            {/* Search Input */}
            <div
              className="custom-search-box mb-3 d-flex align-items-center border rounded"
              style={{ maxWidth: "350px", margin: "0 auto" }}
            >
              <span className="px-2">
                <i className="ri-search-line text-muted"></i>
              </span>
              <input
                type="text"
                className="form-control border-0"
                placeholder="Search for a language"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>

            {/* Filtered Languages List */}
            {searchTerm && filteredLanguages.length > 0 && (
              <ul
                className="list-unstyled border rounded p-2 mb-4"
                style={{ maxWidth: "350px", maxHeight: "120px", margin: "0 auto", overflowY: "auto" }}
              >
                {filteredLanguages.map((lang) => (
                  <li
                    key={lang.code}
                    className="d-flex align-items-center mb-2"
                    onClick={() => handleAddLanguage(lang)}
                    style={{ cursor: "pointer", transition: "background-color 0.3s" }}
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
                  >
                    {/* <img
                      src={`https://flagcdn.com/w20/${lang.code.slice(0, 2).toLowerCase()}.png`}
                      alt={`${lang.name} flag`}
                      className="mr-2"
                    /> */}
                    <img
                      src={get(languages, `${lang?.code}.flag`)}
                      alt={lang?.code}
                      className="me-2 rounded"
                      height="18"
                    />
                    <span className="flex-grow-1">{lang.name}</span>
                  </li>
                ))}
              </ul>
            )}

            {/* Supported Languages List */}
            <h3 className="text-center mb-3">Supported Languages</h3>
            <Row className="justify-content-center">
              {supportedLanguages.map((lang) => (
                <Row md="3" key={lang.code} className="mb-3 tw-justify-center">
                  <Card className="d-flex flex-row justify-content-between align-items-center mb-0 tw-bg-slate-50">
                    <CardBody className="flex-row d-flex">
                      <img
                        src={get(languages, `${lang?.code}.flag`)}
                        alt={lang?.code}
                        className="me-2 rounded"
                        height="18"
                      />
                      <div className="text-center mt-1">{lang.name}</div>
                    </CardBody>
                    <Button
                      className="p-2"
                      color="transparent"
                      size="sm"
                      onClick={() => handleRemoveLanguage(lang.code)}
                      style={{ border: "none" }}
                    >
                      <i className="ri-close-line"></i>
                    </Button>
                  </Card>
                </Row>
              ))}
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
