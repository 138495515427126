import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavLink,
  Row,
  Input,
  Label,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import classnames from "classnames";
import Dropzone, { useDropzone } from "react-dropzone";
//formik
import * as Yup from "yup";
import { Form, Formik, useFormikContext } from "formik";
import { isEmpty } from "lodash";
// Import React FilePond
import { registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
// Import FilePond styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";

import { deepCopy, formatBytes } from "../../../../../helpers/format_helper";

import OptionList from "../Components/OptionList";
import PricesTable from "../Components/Table/PricesTable";
import VariantTable from "../Components/Table/VariantTable";
import withRouter from "../../../../../Components/Common/withRouter";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import BreadCrumbCustom from "../../../../../Components/Common/BreadCrumbCustom";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";
import { Actions } from "../../../../../Components/constants/common";

import {
  addNewProduct,
  deleteProduct,
  listLocation,
  listPriceGroup,
  getProduct,
  resetProductFlag,
  updateProduct,
} from "../../../../../store/actions";
import InputTheme from "../../../../../Components/Common/InputTheme";
import SelectTheme from "../../../../../Components/Common/SelectTheme";
import EditAddBrandModal from "../../Components/EditAddBrandModal";
import EditAddCategoryModal from "../../Components/EditAddCategoryModal";
import { toast } from "react-toastify";
import UnitOptions from "../Components/UnitOptions";
import { Link } from "react-router-dom";
import EditAddPriceGroupModal from "../../../../Settings/Page/PriceGroups/EditAddPriceGroupModal";
import EditAddUnitModal from "../../../../Settings/Page/Units/EditAddUnitModal";
import CKEditorCustom from "../../../../../Components/Common/CKEditorCustom";
import MeasurementTable from "../Components/Table/MeasurementTable";
import ScrollToError from "../../../../../Components/Common/ScrollToError";
import { productOptimizerAPI } from "../../../../../helpers/service_helper";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const ProductDetailsPage = (props) => {
  const { router } = props;
  const { navigate, location, params, t } = router;
  const dispatch = useDispatch();
  const { id } = params;
  document.title = `${t(id ? "Update Product" : "Create Product")} | OptiWarehouse`;

  const formRef = useRef();

  const { productState, productsLoading, productsSuccess, productsAction, productsMsg } = useMemoizedSelector(
    (state) => ({
      productState: state.Products.product,
      productsLoading: state.Products.loading,
      productsAction: state.Products.action,
      productsSuccess: state.Products.success,
      productsError: state.Products.error,
      productsMsg: state.Products.message,
    }),
  );
  const { locations, locationsLoading, locationsSuccess, locationsError } = useMemoizedSelector((state) => ({
    locations: state.Locations.locations,
    locationsLoading: state.Locations.loading,
    locationsSuccess: state.Locations.success,
    locationsError: state.Locations.error,
  }));
  const { priceGroups, priceGroupsLoading, priceGroupsSuccess, priceGroupsError } = useMemoizedSelector((state) => ({
    priceGroups: state.PriceGroups.priceGroups,
    priceGroupsLoading: state.PriceGroups.loading,
    priceGroupsSuccess: state.PriceGroups.success,
    priceGroupsError: state.PriceGroups.error,
  }));
  const { unitsState, unitsLoading, unitsSuccess, unitsError } = useMemoizedSelector((state) => ({
    unitsState: state.Units.units,
    unitsLoading: state.Units.loading,
    unitsSuccess: state.Units.success,
    unitsError: state.Units.error,
  }));

  const [images, setImages] = useState([]);
  const [options, setOptions] = useState([]);
  const [variants, setVariants] = useState([]);
  const [units, setUnits] = useState([{}]);
  const [variantImages, setVariantImages] = useState([]);

  useEffect(() => {
    if (productState && !productsLoading) {
      setImages(productState?.images ?? []);
      setOptions(productState?.options ?? []);
      setVariants(productState?.variants ?? []);
      let newData = [];
      (productState?.variants ?? []).forEach(
        (item) =>
          !isEmpty(item.option1) && !isEmpty(item.images) && newData.push({ key: item.option1, data: item.images }),
      );
      setVariantImages(newData);
      setSelectedFiles([]);
      const newUnits = [];
      const dict_sku = {};
      (productState?.variants ?? [])
        .filter((item) => item?.original_sku && item?.unit?.id)
        .forEach((item) =>
          dict_sku[item.original_sku]
            ? dict_sku[item.original_sku].push(item.unit)
            : (dict_sku[item.original_sku] = [item.unit]),
        );
      Object.keys(dict_sku).forEach((item) =>
        dict_sku[item].forEach((subItem) => newUnits.push({ variant: item, unit: subItem })),
      );
      setUnits(newUnits);
    }
  }, [productState, productsLoading]);

  // collapse state
  const [isBasicCollapse, setIsBasicCollapse] = useState(false);
  const [isOptionsCollapse, setIsOptionsCollapse] = useState(true);
  const [isUnitsCollapse, setIsUnitsCollapse] = useState(true);
  const [isPriceCollapse, setIsPriceCollapse] = useState(true);
  const [isMeasurementCollapse, setIsMeasurementCollapse] = useState(true);

  const [showUnitModal, setShowUnitModal] = useState(false);
  const toggleUnitModal = () => {
    if (!unitsLoading) {
      setShowUnitModal(!showUnitModal);
    }
  };
  const [showPriceModal, setShowPriceModal] = useState(false);
  const togglePriceModal = () => {
    if (!priceGroupsLoading) {
      setShowPriceModal(!showPriceModal);
    }
  };

  const handleClickScrollSpyCallback = useCallback(
    (data) => {
      setTimeout(() => {
        let e = document.getElementById(data);
        e.scrollIntoView(true);
      }, 100);
    },
    [isBasicCollapse, isOptionsCollapse, isPriceCollapse, isMeasurementCollapse, isUnitsCollapse],
  );
  const handleClickScrollSpy = (data) => {
    let e = document.getElementById(data);
    e.scrollIntoView(true);
  };

  // add new modal
  const [isOpenCategoriesModal, setIsOpenCategoriesModal] = useState(false);
  const [isOpenBrandsModal, setIsOpenBrandsModal] = useState(false);
  const toggleCategoriesModal = () => {
    setIsOpenCategoriesModal(false);
  };
  const toggleBrandsModal = () => {
    setIsOpenBrandsModal(false);
  };

  const handleAddOption = () => {
    if (options.length < 3) {
      let check = options.find((op) => isEmpty(op.name));
      if (check) {
        toast.error(t("Please fill option name before add new option"));
      } else {
        setOptions([...options, { name: "", values: [] }]);
        setUnits([]);
      }
    }
  };
  const handleDeleteOption = (i) => {
    setOptions(options.filter((item, index) => index !== i));
    setUnits([]);
  };

  const handleVariants = (data) => {
    setVariants(data);
  };

  const handleChangeUnits = (data) => {
    setUnits(data);
  };
  const [selectedFiles, setSelectedFiles] = useState([]);

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const content = event.target.result;
        file.content = content;
        file.preview = URL.createObjectURL(file);
        file.formattedSize = formatBytes(file.size);
        resolve(file);
      };

      reader.onerror = (event) => {
        reject(event.target.error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleAcceptedFiles = useCallback((files) => {
    if (files.length > 0) {
      const promises = Array.from(files).map((file) => readFileContent(file));
      Promise.all(promises)
        .then((contents) => {
          setSelectedFiles((prevFiles) => {
            const newFiles = [...prevFiles, ...contents];
            return newFiles;
          });
        })
        .catch((error) => {
          console.error("Error reading files:", error);
        });
    }
  }, []);

  const handleDeleteSelectedFiles = (index) => {
    setSelectedFiles(selectedFiles.filter((item, i) => i !== index));
  };
  const handleDeleteImage = (index) => {
    setImages(images.filter((item, i) => i !== index));
  };

  const handleSetVariantsByOption = (options, variantImages, name, sku) => {
    // if (options.length === 0 || isEmpty(options[0].name) || isEmpty(options[0].values)) {
    //   setVariants([]);
    //   return;
    // }

    // Initialize arrays for new data
    const newData1 = [];
    const newData2 = [];
    const newData3 = [];

    // Iterate through options
    options.forEach((option, optionIndex) => {
      // Check if option has values and a name
      if (!isEmpty(option.values) && !isEmpty(option.name)) {
        // Create a base newVariant object
        const newVariantBase = {
          barcode: null,
          images: null,
          prices: [],
          name: name,
          option1: null,
          option2: null,
          option3: null,
          sku: sku,
        };

        // Create a newVariant based on the current option value
        const createNewVariant = (baseVariant, value) => {
          const newVariant = { ...baseVariant };
          newVariant[`option${optionIndex + 1}`] = value;
          newVariant.name += ` - ${value}`;
          newVariant.sku += `-${value}`;
          return newVariant;
        };

        // Process options based on their index
        if (optionIndex === 0) {
          option.values.forEach((item) => {
            newData1.push(createNewVariant(newVariantBase, item));
          });
        } else if (optionIndex === 1) {
          newData1.forEach((v1) => {
            option.values.forEach((item) => {
              newData2.push(createNewVariant(v1, item));
            });
          });
        } else if (optionIndex === 2) {
          newData2.forEach((v2) => {
            option.values.forEach((item) => {
              newData3.push(createNewVariant(v2, item));
            });
          });
        }
      }
    });

    // Determine newData based on available data
    const newData = !isEmpty(newData3) ? newData3 : !isEmpty(newData2) ? newData2 : newData1;

    // Update newVariants with images
    const newVariants = newData.map((item) => {
      const variantIndex = variants.findIndex(
        (variant) =>
          (variant.option1 === item.option1 || variant.option1 === "Product") &&
          variant.option2 === item.option2 &&
          variant.option3 === item.option3,
      );

      const check = variantImages.find((vi) => vi.key === item.option1);

      if (variantIndex > -1) {
        if (check) {
          variants[variantIndex].images = check.data.map((img) =>
            img.content ? { name: img.name, image: img.content } : img,
          );
        }
        return variants[variantIndex];
      } else {
        if (check) {
          item.images = check.data.map((img) => (img.content ? { name: img.name, image: img.content } : img));
        }
        return item;
      }
    });

    // Update state with newVariants
    setVariants(newVariants);
  };

  const handleFormatSkuUnit = () => {
    const dict_sku_unit = {};
    units.forEach((item) => {
      if (item.variant && item.unit) {
        if (item.variant === "ALL") {
          if (variants.length > 0) {
            variants.forEach((v) => {
              if (!v?.unit?.id) {
                if (dict_sku_unit[v.sku]) {
                  !dict_sku_unit[v.sku].find((vu) => vu.id === item.unit.id) && dict_sku_unit[v.sku].push(item.unit);
                } else {
                  dict_sku_unit[v.sku] = [item.unit];
                }
              }
            });
          } else {
            if (dict_sku_unit[formRef.current?.values?.sku]) {
              !dict_sku_unit[formRef.current?.values?.sku].find((vu) => vu.id === item.unit.id) &&
                dict_sku_unit[formRef.current?.values?.sku].push(item.unit);
            } else {
              dict_sku_unit[formRef.current?.values?.sku] = [item.unit];
            }
          }
        } else {
          if (dict_sku_unit[item.variant]) {
            !dict_sku_unit[item.variant].find((vu) => vu?.id === item.unit.id) &&
              dict_sku_unit[item.variant].push(item.unit);
          } else {
            dict_sku_unit[item.variant] = [item.unit];
          }
        }
      }
    });
    return dict_sku_unit;
  };

  const handleSetVariantsByUnit = () => {
    const dict_sku_unit = handleFormatSkuUnit();
    const newVariants = [];
    if (variants.length > 0) {
      variants.forEach((item) => {
        if (!item?.unit?.id) {
          if (dict_sku_unit?.[item.sku]) {
            item.original_sku = item.sku;
            newVariants.push(item);
            dict_sku_unit?.[item.sku].forEach((subItem) => {
              const availableSkuUnit = variants.find(
                (v) => v.original_sku === item.original_sku && v?.unit?.id && subItem.id === v?.unit?.id,
              );
              if (!availableSkuUnit) {
                const newVariant = {
                  ...item,
                  name: `${item.name} (${subItem.name})`,
                  original_sku: item.sku,
                  sku: item.sku + "-" + subItem.ratio,
                  prices: [],
                  unit: subItem,
                };
                delete newVariant.slug;
                newVariants.push(newVariant);
              } else {
                newVariants.push(availableSkuUnit);
              }
            });
          } else {
            newVariants.push(item);
          }
        }
      });
    } else {
      if (dict_sku_unit?.[formRef.current?.values?.sku]) {
        const newItem = {
          option1: "Product",
          name: formRef.current?.values?.name,
          sku: formRef.current?.values?.sku,
          original_sku: formRef.current?.values?.sku,
          images: images,
          prices: [],
        };
        newVariants.push(newItem);
        dict_sku_unit?.[formRef.current?.values?.sku].forEach((subItem) => {
          const newVariant = {
            ...newItem,
            name: `${newItem.name} (${subItem.name})`,
            original_sku: newItem.sku,
            sku: newItem.sku + "-" + subItem.ratio,
            prices: [],
            unit: subItem,
          };
          delete newVariant.slug;
          newVariants.push(newVariant);
        });
      }
    }
    setVariants(newVariants);
  };

  useEffect(() => {
    handleSetVariantsByUnit();
  }, [units]);

  const handleChangeSku = (data) => {
    if (variants.length > 0) {
      const newVariants = variants.map((item) => {
        item.option1 && (item.sku = data + "-" + item.option1);
        item.option2 && (item.sku = item.sku + "-" + item.option2);
        item.option3 && (item.sku = item.sku + "-" + item.option3);
        item.unit && (item.sku = item.sku + "-" + item.unit.ratio);
        return item;
      });
      setVariants(newVariants);
    }
  };

  const handleChangeName = (data) => {
    if (variants.length > 0) {
      const newVariants = variants.map((item) => {
        item.unit && (item.name = `${item.name} - (${item.unit.name})`);
        item.option1 && (item.name = data + " - " + item.option1);
        item.option2 && (item.name = item.name + " - " + item.option2);
        item.option3 && (item.name = item.name + " - " + item.option3);
        return item;
      });
      setVariants(newVariants);
    }
  };

  const handleChangePrices = (data, setFieldValue) => {
    setFieldValue("prices", data);
  };

  const handleChangeMeasurements = (data, setFieldValue) => {
    setFieldValue("measurements", data);
  };

  const handleApplyMeasurements = (data, setFieldValue) => {
    const newVariants = variants.map((item) => {
      item.measurements = data;
      return item;
    });
    handleVariants(newVariants);
  };
  const handleApplyPrice = ({ activeTabPrice, priceAll }, prices, setFieldValue) => {
    if (variants.length > 0) {
      let newVariant = deepCopy(variants);
      if (priceGroups.length === 0) {
        return;
      } else {
        let priceGroup = priceGroups[activeTabPrice - 1];
        newVariant = newVariant.map((variant) => {
          if (priceAll >= 0) {
            let variantPriceIndex = variant.prices.findIndex((item) => item.price_group.id === priceGroup.id);
            if (variantPriceIndex > -1) {
              variant.prices[variantPriceIndex].price = priceAll;
            } else {
              let newPriceGroup = priceGroups.find((item) => item.id === priceGroup.id);
              variant.prices.push({ price: priceAll, price_group: { id: newPriceGroup.id, name: newPriceGroup.name } });
            }
          }
          return variant;
        });
      }
      handleVariants(newVariant);
    } else {
      let newPrices = deepCopy(prices ?? []);
      if (priceGroups.length === 0) {
        return;
      } else {
        let priceGroup = priceGroups[activeTabPrice - 1];
        if (priceAll >= 0) {
          let priceIndex = newPrices.findIndex((item) => item.price_group.id === priceGroup.id);
          if (priceIndex > -1) {
            newPrices[priceIndex].price = priceAll;
          } else {
            let newPriceGroup = priceGroups.find((item) => item.id === priceGroup.id);
            newPrices.push({ price: priceAll, price_group: { id: newPriceGroup.id, name: newPriceGroup.name } });
          }
        }
      }
      handleChangePrices(newPrices, setFieldValue);
    }
  };

  const validation = {
    enableReinitialize: true,
    initialValues: {
      brand: productState?.brand ?? null,
      category: productState?.category ?? null,
      description: productState?.description ?? "",
      name: productState?.name ?? "",
      prices: productState?.prices ?? null,
      inventories: productState?.inventories ?? null,
      publish: productState?.publish ?? false,
      shortDescription: productState?.shortDescription ?? null,
      sku: productState?.sku ?? "",
      tags: productState?.tags ?? null,
      measurements: productState?.measurements ?? {
        height_unit: productState?.measurements?.height_unit ?? "cm",
        height_value: productState?.measurements?.height_value ?? 0,
        length_unit: productState?.measurements?.length_unit ?? "cm",
        length_value: productState?.measurements?.length_value ?? 0,
        width_unit: productState?.measurements?.width_unit ?? "cm",
        width_value: productState?.measurements?.width_value ?? 0,
        weight_unit: productState?.measurements?.weight_unit ?? "g",
        weight_value: productState?.measurements?.weight_value ?? 0,
      },
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Please Enter Product Name")),
      sku: Yup.string().required(t("Please Enter Product Sku")),
      // brand: Yup.object().required(t("Please Select Product Brand")),
      // category: Yup.object().required(t("Please Select Product Category")),
      // height_value: Yup.number().moreThan(0, t("Height must be greater than 0")),
      // length_value: Yup.number().moreThan(0, t("Length must be greater than 0")),
      // width_value: Yup.number().moreThan(0, t("Width must be greater than 0")),
      // weight_value: Yup.number().moreThan(0, t("Weight must be greater than 0")),
    }),
    onSubmit: (values) => {
      const newProduct = {
        ...values,
        brand: values.brand ?? null,
        category: values.category ?? null,
        description: values.description,
        name: values.name,
        options,
        prices: variants.length > 0 ? null : values.prices,
        inventories: variants.length > 0 ? null : values.inventories,
        publish: values.publish,
        shortDescription: values.shortDescription,
        sku: values.sku,
        tags: values?.tags || null,
        variants:
          variants.length > 0
            ? variants
            : [
                {
                  name: `${values.name} - 1`,
                  sku: `${values.sku}-1`,
                  barcode: null,
                  prices: [],
                  images: [],
                },
              ],
        barcode: null,
      };
      const newImages = selectedFiles.map((item) => {
        return { name: item.name, image: item.content };
      });
      newProduct.images = [...images, ...newImages];
      newProduct.variants.forEach((item) => {
        let check = variantImages.find((vi) => vi.key === item.option1);
        if (check) {
          item.images = check.data.map((item) => {
            if (item.id) {
              return item;
            }
            return { name: item.name, image: item.content };
          });
        }
      });
      const handleAfterSubmit = (data) => {
        navigate(`/products/${data.id}`);
      };
      if (options.find((item) => isEmpty(item.name))) {
        if (window.confirm(t("You must to fill option name or your data will be missed. Do you wanna submit?"))) {
          newProduct.options = newProduct.options.filter((item) => !isEmpty(item.name));
          if (id) {
            dispatch(updateProduct({ ...productState, ...newProduct }, handleAfterSubmit));
          } else {
            dispatch(addNewProduct(newProduct, handleAfterSubmit));
          }
        }
      } else {
        newProduct.options = newProduct.options.filter((item) => !isEmpty(item.name));
        if (id) {
          dispatch(updateProduct({ ...productState, ...newProduct }, handleAfterSubmit));
        } else {
          dispatch(addNewProduct(newProduct, handleAfterSubmit));
        }
      }
    },
  };

  const handleClickDelete = () => {
    if (productState) {
      dispatch(deleteProduct(productState));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getProduct(id));
    } else {
      dispatch(resetProductFlag());
    }
    dispatch(listLocation({ limit: 20 }));
    dispatch(listPriceGroup({ limit: 20 }));
  }, [dispatch]);

  // State to hold the API response
  const [apiResponse, setApiResponse] = useState(null); // Add state to store API response

  // State to manage dropdown visibility
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to toggle dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [modalOpen, setModalOpen] = useState(false); // State for modal visibility

  const toggleModal = () => setModalOpen(!modalOpen); // Function to toggle modal

  return (
    <div className="page-content">
      <Container fluid>
        <EditAddPriceGroupModal show={showPriceModal} toggle={togglePriceModal} isEdit={false} priceGroup={null} />
        <EditAddUnitModal show={showUnitModal} toggle={toggleUnitModal} unit={null} />

        <BreadCrumbCustom text="Back to product list" navigateTo="/products" t={t}>
          <div className="hstack gap-2">
            {id && (
              <ButtonTheme
                type="button"
                className="btn btn-danger w-sm"
                onClick={handleClickDelete}
                loading={productsAction === Actions.DELETE && productsLoading}
                disabled={productsAction === Actions.DELETE && productsLoading}
              >
                {t("Delete")}
              </ButtonTheme>
            )}
            <ButtonTheme
              type="button"
              className="btn btn-outline-primary w-sm"
              onClick={() => formRef.current.handleSubmit()}
              loading={(productsAction === Actions.UPDATE || productsAction === Actions.CREATE) && productsLoading}
              disabled={(productsAction === Actions.UPDATE || productsAction === Actions.CREATE) && productsLoading}
            >
              {t(id ? "Update" : "Create")}
            </ButtonTheme>
          </div>
        </BreadCrumbCustom>

        {/*  {productsLoading && productsAction === Actions.GET && id ? (
          <Loader />
        ) : ( */}
        <Formik
          enableReinitialize={true}
          initialValues={validation.initialValues}
          validationSchema={validation.validationSchema}
          onSubmit={validation.onSubmit}
          innerRef={formRef}
          validateOnBlur={true}
          validateOnChange={false}
        >
          {({ values, setFieldValue, errors, touched, handleSubmit }) => {
            // Function to call the productOptimizerAPI
            const handleCallOptimizerAPI = async () => {
              const stripHtmlTags = (html) => {
                return html.replace(/<[^>]*>/g, ""); // Remove HTML tags
              };

              const optimizerData = {
                title: values.name, // Get title from Product Name input
                description: stripHtmlTags(values.description), // Strip HTML tags from description
                target_marketplace: "amazon|ebay|general",
                max_title_length: 200,
              };

              try {
                const response = await productOptimizerAPI(optimizerData); // Call the API
                console.log("API Response:", response); // Handle the response as needed
                setApiResponse(response); // Store the response in state
                if (!modalOpen) toggleModal();
              } catch (error) {
                console.error("Error calling product optimizer API:", error); // Handle errors
              }
            };

            const openModalWithExistingData = () => {
              if (apiResponse) {
                toggleModal(); // Open modal if there is existing data
              } else {
                handleCallOptimizerAPI(); // Call API if no existing data
              }
            };

            return (
              <Form>
                <ScrollToError />
                <EditAddBrandModal
                  show={isOpenBrandsModal}
                  toggle={toggleBrandsModal}
                  onChangeBrand={(data) => {
                    setFieldValue("brand", data);
                  }}
                />
                <EditAddCategoryModal
                  show={isOpenCategoriesModal}
                  toggle={toggleCategoriesModal}
                  onChangeCategory={(data) => {
                    setFieldValue("category", data);
                  }}
                />
                <div className="d-flex gap-3">
                  <div className="flex-grow-1">
                    <Card id="basic-information">
                      <div className="accordion">
                        <div className="accordion-item">
                          <h5 className="accordion-header">
                            <ButtonTheme
                              className={classnames("accordion-button", { collapsed: isBasicCollapse })}
                              type="button"
                              onClick={() => {
                                setIsBasicCollapse(!isBasicCollapse);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {t("Basic Information")}
                            </ButtonTheme>
                          </h5>
                        </div>
                      </div>
                      <Collapse isOpen={!isBasicCollapse}>
                        <CardBody>
                          <div id="product-images" className="mb-3">
                            <h5 className="fs-14 mb-1">{t("Images")}</h5>
                            <p className="text-muted">{t("Add Product Images")}</p>
                            <div className="dropzone">
                              <div className="row justify-content-center dz-message">
                                {images.map((item, i) => (
                                  <div key={i + "-image"} className="col-auto p-0 m-2">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-md rounded bg-light"
                                      style={{ objectFit: "contain", height: "80px", width: "80px" }}
                                      alt={""}
                                      src={item.url ?? item.preview}
                                    />
                                    <i
                                      className="position-absolute top-0 start-100 translate-middle ri-close-circle-fill text-danger fs-19 cursor-pointer"
                                      onClick={() => handleDeleteImage(i)}
                                    ></i>
                                  </div>
                                ))}
                                {selectedFiles.map((item, i) => (
                                  <div key={i + "-file"} className="col-auto p-0 m-2">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-md rounded bg-light"
                                      alt={""}
                                      src={item.preview}
                                    />
                                    <i
                                      className="position-absolute top-0 start-100 translate-middle ri-close-circle-fill text-danger fs-19 cursor-pointer"
                                      onClick={() => handleDeleteSelectedFiles(i)}
                                    ></i>
                                  </div>
                                ))}
                                <Dropzone
                                  accept={{ "image/*": [".jpeg", ".jpg", ".png"] }}
                                  onDrop={(acceptedFiles) => {
                                    handleAcceptedFiles(acceptedFiles);
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      className="avatar-md col-auto p-0 m-2 border border-dashed border-2 cursor-pointer rounded-3"
                                      {...getRootProps()}
                                    >
                                      <i className="display-4 text-muted ri-upload-cloud-2-fill rounded" />
                                    </div>
                                  )}
                                </Dropzone>
                              </div>
                              <div className="row justify-content-center">
                                <Label
                                  className="m-3 w-auto cursor-pointer text-decoration-underline"
                                  for="dropzone-images"
                                >
                                  {t("Upload")}
                                </Label>
                                <Input
                                  className="d-none"
                                  type="file"
                                  accept="image/*"
                                  multiple
                                  id="dropzone-images"
                                  onChange={(e) => {
                                    let files = [];
                                    Object.keys(e.target.files).map((item) => files.push(e.target.files[item]));
                                    let newFiles = files.filter((item) => item.type.startsWith("image/"));
                                    handleAcceptedFiles(newFiles);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <ButtonTheme
                            type="button"
                            className="btn btn-primary mb-3"
                            onClick={openModalWithExistingData}
                          >
                            Optimize with AI
                          </ButtonTheme>
                          <InputTheme
                            name={"name"}
                            placeholder="Enter product name"
                            label={"Product Name"}
                            onTrigger={(data) => {
                              handleChangeName(data);
                            }}
                          />
                          <div id="product-description" className="mb-3">
                            <h5 className="fs-14 mb-1">{t("Description")}</h5>
                            <p className="text-muted mb-2">{t("Description details")}</p>
                            <div
                              style={{
                                border: `1px solid ${
                                  errors.description && touched.description ? "#f06548" : "#d1d1d1"
                                }`,
                                backgroundColor: "#fff",
                              }}
                              name=""
                            >
                              <CKEditorCustom
                                imagePrefix="products"
                                value={values?.description}
                                onChangeValue={(data) => {
                                  setFieldValue("description", data);
                                }}
                              />
                            </div>
                            {errors.description && touched.description && (
                              <div className="position-absolute fs-10" style={{ color: "#f06548" }}>
                                {errors.description}
                              </div>
                            )}
                          </div>

                          <InputTheme
                            type="textarea"
                            name={"shortDescription"}
                            placeholder={t("Must enter minimum of a 100 characters")}
                            label={
                              <>
                                <h5 className="fs-14 mb-1">{t("Short description")}</h5>
                                <p className="text-muted mb-2">{t("Add short description for product")}</p>
                              </>
                            }
                          />
                          <SelectTheme
                            name="brand"
                            remote={true}
                            selectDataOrigin={true}
                            isObjectValueSelect={true}
                            path="product/brands"
                            label={
                              <div className="d-flex align-items-end justify-content-between w-100">
                                <span>{t("Brand")}</span>
                                <div
                                  className="float-end text-primary cursor-pointer text-decoration-underline"
                                  onClick={() => {
                                    setIsOpenBrandsModal(true);
                                  }}
                                >
                                  {t("Add New")}
                                </div>
                              </div>
                            }
                          />
                          <SelectTheme
                            name="category"
                            remote={true}
                            selectDataOrigin={true}
                            isObjectValueSelect={true}
                            path="product/categories"
                            label={
                              <div className="d-flex align-items-end justify-content-between w-100">
                                <span>{t("Category")}</span>
                                <span
                                  className="float-end text-primary cursor-pointer text-decoration-underline"
                                  onClick={() => {
                                    setIsOpenCategoriesModal(true);
                                  }}
                                >
                                  {t("Add New")}
                                </span>
                              </div>
                            }
                          />
                          <InputTheme
                            name={"sku"}
                            placeholder="Enter product sku"
                            label={"Sku"}
                            onTrigger={(data) => {
                              handleChangeSku(data);
                            }}
                          />
                          <InputTheme name={"publish"} type="checkbox" label={"Publish"} />
                          <InputTheme
                            name={"tags"}
                            label={"Tags"}
                            type="tags"
                            placeholder={"Enter tags and press enter"}
                          />
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card id="product-options">
                      <div className="accordion">
                        <div className="accordion-item">
                          <h5 className="accordion-header">
                            <ButtonTheme
                              className={classnames("accordion-button", { collapsed: isOptionsCollapse })}
                              type="button"
                              onClick={() => {
                                setIsOptionsCollapse(!isOptionsCollapse);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {t("Options")}
                            </ButtonTheme>
                          </h5>
                        </div>
                      </div>

                      <Collapse isOpen={!isOptionsCollapse}>
                        <CardBody>
                          <p className="text-muted">
                            {t("Create variant when having more than one choice, such as about sizes or colors")}
                          </p>
                          {options.map((item, index) => (
                            <div id={`product-option-${index + 1}`} key={index}>
                              <OptionList
                                productImages={[...images, ...selectedFiles]}
                                optionIndex={index}
                                optionData={item}
                                variantImages={variantImages}
                                variants={variants}
                                onChangeOptions={(i, data) => {
                                  let newData = options.map((item, index) => {
                                    if (index === i) {
                                      return data;
                                    } else {
                                      return item;
                                    }
                                  });
                                  handleSetVariantsByOption(newData, variantImages, values.name, values.sku);
                                  setOptions(newData);
                                  setUnits([]);
                                  setVariants([]);
                                }}
                                onDeleteOption={handleDeleteOption}
                                onChangeVariantImages={(data) => {
                                  setVariantImages(data);
                                  handleSetVariantsByOption(options, data, values.name, values.sku);
                                }}
                                options={options}
                              />
                            </div>
                          ))}
                          <ButtonTheme
                            type="button"
                            className="btn btn-outline-primary mb-3"
                            disabled={options.length >= 3}
                            onClick={handleAddOption}
                          >
                            <i className="ri-add-line align-bottom me-1"></i> {t("Add option")} ({options.length}/3)
                          </ButtonTheme>
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card id="product-unit-options">
                      <div className="accordion">
                        <div className="accordion-item">
                          <h5 className="accordion-header">
                            <ButtonTheme
                              className={classnames("accordion-button", { collapsed: isUnitsCollapse })}
                              type="button"
                              onClick={() => {
                                setIsUnitsCollapse(!isUnitsCollapse);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {t("Units")}
                            </ButtonTheme>
                          </h5>
                        </div>
                      </div>

                      <Collapse isOpen={!isUnitsCollapse}>
                        <CardBody>
                          <div className="hstack justify-content-between">
                            <p className="text-muted mb-0">
                              {t("Create variant when having more than one choice, such as units")}
                            </p>
                            <Link className="link-primary text-decoration-underline" onClick={toggleUnitModal}>
                              {t("Add New")}
                            </Link>
                          </div>
                          <UnitOptions units={units} variants={variants} onChangeUnits={handleChangeUnits} />
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card id="product-prices">
                      <div className="accordion">
                        <div className="accordion-item">
                          <h5 className="accordion-header">
                            <ButtonTheme
                              className={classnames("accordion-button", { collapsed: isPriceCollapse })}
                              type="button"
                              onClick={() => {
                                setIsPriceCollapse(!isPriceCollapse);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {t("Prices")}
                            </ButtonTheme>
                          </h5>
                        </div>
                      </div>

                      <Collapse isOpen={!isPriceCollapse}>
                        <CardBody>
                          <div className="hstack justify-content-between">
                            <p className="text-muted mb-0">{t("Variant prices")}</p>
                            <Link className="link-primary text-decoration-underline" onClick={togglePriceModal}>
                              {t("Add New")}
                            </Link>
                          </div>
                          {variants.length > 0 ? (
                            <VariantTable
                              variants={variants}
                              onChangeVariants={handleVariants}
                              options={options}
                              onApplyPrice={(data) => handleApplyPrice(data, values.prices, setFieldValue)}
                              onApplyMeasurements={(data) => handleApplyMeasurements(data, setFieldValue)}
                              router={router}
                              locations={locations}
                              priceGroups={priceGroups}
                            />
                          ) : (
                            <PricesTable
                              priceGroups={priceGroups}
                              prices={values.prices ?? []}
                              sku={values.sku}
                              onChangePrice={(data) => handleChangePrices(data, setFieldValue)}
                              onApplyPrice={(data) => handleApplyPrice(data, values.prices, setFieldValue)}
                            />
                          )}
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card id="product-measurement">
                      <div className="accordion">
                        <div className="accordion-item">
                          <h5 className="accordion-header">
                            <ButtonTheme
                              className={classnames("accordion-button", { collapsed: isMeasurementCollapse })}
                              type="button"
                              onClick={() => {
                                setIsMeasurementCollapse(!isMeasurementCollapse);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {t("Measurements")}
                            </ButtonTheme>
                          </h5>
                        </div>
                      </div>
                      <Collapse isOpen={!isMeasurementCollapse}>
                        <CardBody>
                          {variants.length > 0 ? (
                            <VariantTable
                              variants={variants}
                              onChangeVariants={handleVariants}
                              options={options}
                              isPrice={false}
                              onApplyPrice={(data) => handleApplyPrice(data, values.prices, setFieldValue)}
                              onApplyMeasurements={(data) => handleApplyMeasurements(data, setFieldValue)}
                              router={router}
                              locations={locations}
                              priceGroups={priceGroups}
                            />
                          ) : (
                            <MeasurementTable
                              locations={locations}
                              measurements={values.measurements}
                              sku={values.sku}
                              onChangeMeasurements={(data) => handleChangeMeasurements(data, setFieldValue)}
                            />
                          )}
                        </CardBody>
                      </Collapse>
                    </Card>
                  </div>
                  <div className="flex-shrink-0">
                    <div className="position-sticky" style={{ top: "calc(70px + 1.5rem + 60px)" }}>
                      <Card>
                        <Nav id="navbar-scroll-spy" className="flex-column">
                          <Nav className="nav-pills flex-column p-3">
                            <NavLink
                              className="cursor-pointer"
                              onClick={() => {
                                if (isBasicCollapse) {
                                  setIsBasicCollapse(false);
                                  handleClickScrollSpyCallback("basic-information");
                                } else {
                                  handleClickScrollSpy("basic-information");
                                }
                                setIsOptionsCollapse(true);
                                setIsMeasurementCollapse(true);
                                setIsPriceCollapse(true);
                                setIsUnitsCollapse(true);
                              }}
                            >
                              <i className="ri-information-line align-middle me-2 fs-16"></i>{" "}
                              <span>{t("Basic information")}</span>
                            </NavLink>
                            <NavLink
                              className="cursor-pointer"
                              onClick={() => {
                                if (isOptionsCollapse) {
                                  setIsOptionsCollapse(false);
                                  handleClickScrollSpyCallback("product-options");
                                } else {
                                  handleClickScrollSpy("product-options");
                                }
                                setIsBasicCollapse(true);
                                setIsMeasurementCollapse(true);
                                setIsPriceCollapse(true);
                                setIsUnitsCollapse(true);
                              }}
                            >
                              <i className="ri-apps-2-line align-middle me-2 fs-16"></i>
                              <span>{t("Product options")}</span>
                            </NavLink>
                            {options.length > 0 && (
                              <Nav className="nav nav-pills flex-column">
                                {options?.[0] && (
                                  <NavLink
                                    className="cursor-pointer"
                                    onClick={() => {
                                      if (isOptionsCollapse) {
                                        setIsOptionsCollapse(false);
                                        handleClickScrollSpyCallback("product-option-1");
                                      } else {
                                        handleClickScrollSpy("product-option-1");
                                      }
                                      setIsBasicCollapse(true);
                                      setIsMeasurementCollapse(true);
                                      setIsPriceCollapse(true);
                                      setIsUnitsCollapse(true);
                                    }}
                                  >
                                    <i className="ri-subtract-fill align-middle me-2 fs-15"></i>{" "}
                                    <span>{t("Option")} 1</span>
                                  </NavLink>
                                )}
                                {options?.[1] && (
                                  <NavLink
                                    className="cursor-pointer"
                                    onClick={() => {
                                      if (isOptionsCollapse) {
                                        setIsOptionsCollapse(false);
                                        handleClickScrollSpyCallback("product-option-2");
                                      } else {
                                        handleClickScrollSpy("product-option-2");
                                      }
                                      setIsBasicCollapse(true);
                                      setIsMeasurementCollapse(true);
                                      setIsPriceCollapse(true);
                                      setIsUnitsCollapse(true);
                                    }}
                                  >
                                    <i className="ri-subtract-fill align-middle me-2 fs-15"></i>{" "}
                                    <span>{t("Option")} 2</span>
                                  </NavLink>
                                )}
                                {options?.[2] && (
                                  <NavLink
                                    className="cursor-pointer"
                                    onClick={() => {
                                      if (isOptionsCollapse) {
                                        setIsOptionsCollapse(false);
                                        handleClickScrollSpyCallback("product-option-3");
                                      } else {
                                        handleClickScrollSpy("product-option-3");
                                      }
                                      setIsBasicCollapse(true);
                                      setIsMeasurementCollapse(true);
                                      setIsPriceCollapse(true);
                                      setIsUnitsCollapse(true);
                                    }}
                                  >
                                    <i className="ri-subtract-fill align-middle me-2 fs-15"></i>{" "}
                                    <span>{t("Option")} 3</span>
                                  </NavLink>
                                )}
                              </Nav>
                            )}
                            <NavLink
                              className="cursor-pointer"
                              onClick={() => {
                                if (isUnitsCollapse) {
                                  setIsUnitsCollapse(false);
                                  handleClickScrollSpyCallback("product-unit-options");
                                } else {
                                  handleClickScrollSpy("product-unit-options");
                                }
                                setIsBasicCollapse(true);
                                setIsPriceCollapse(true);
                                setIsMeasurementCollapse(true);
                                setIsOptionsCollapse(true);
                              }}
                            >
                              <i className="ri-instance-line me-2 fs-16"></i> <span>{t("Product units")}</span>
                            </NavLink>
                            <NavLink
                              className="cursor-pointer"
                              onClick={() => {
                                if (isPriceCollapse) {
                                  setIsPriceCollapse(false);
                                  handleClickScrollSpyCallback("product-prices");
                                } else {
                                  handleClickScrollSpy("product-prices");
                                }
                                setIsBasicCollapse(true);
                                setIsMeasurementCollapse(true);
                                setIsOptionsCollapse(true);
                                setIsUnitsCollapse(true);
                              }}
                            >
                              <i className="bx bx-money align-middle me-2 fs-16"></i> <span>{t("Product prices")}</span>
                            </NavLink>
                            <NavLink
                              className="cursor-pointer"
                              onClick={() => {
                                if (isMeasurementCollapse) {
                                  setIsMeasurementCollapse(false);
                                  handleClickScrollSpyCallback("product-measurement");
                                } else {
                                  handleClickScrollSpy("product-measurement");
                                }
                                setIsBasicCollapse(true);
                                setIsOptionsCollapse(true);
                                setIsPriceCollapse(true);
                                setIsUnitsCollapse(true);
                              }}
                            >
                              <i className="ri-home-gear-line align-middle me-2 fs-16"></i>{" "}
                              <span>{t("Measurements")}</span>
                            </NavLink>
                          </Nav>
                        </Nav>
                      </Card>
                    </div>
                  </div>
                </div>

                {/* Modal for AI Suggestions */}
                <Modal isOpen={modalOpen} toggle={toggleModal} className="modal-xl">
                  <ModalHeader toggle={toggleModal}></ModalHeader>
                  <ModalBody>
                    <div className="d-flex flex-row align-items-start">
                      <div style={{ flex: 1 }}>
                        <h4 className="mb-3">Yours product</h4>
                        <InputTheme
                          name={"name"}
                          placeholder="Enter product name"
                          label={"Product Name"}
                          onTrigger={(data) => {
                            handleChangeName(data);
                          }}
                        />
                        <div id="product-description" className="mb-3">
                          <h5 className="fs-14 mb-1">{t("Description")}</h5>
                          <p className="text-muted mb-2">{t("Description details")}</p>
                          <div
                            style={{
                              border: `1px solid ${errors.description && touched.description ? "#f06548" : "#d1d1d1"}`,
                              backgroundColor: "#fff",
                            }}
                            name=""
                          >
                            <CKEditorCustom
                              imagePrefix="products"
                              value={values?.description}
                              onChangeValue={(data) => {
                                setFieldValue("description", data);
                              }}
                            />
                          </div>
                          {errors.description && touched.description && (
                            <div className="position-absolute fs-10" style={{ color: "#f06548" }}>
                              {errors.description}
                            </div>
                          )}
                        </div>
                      </div>
                      <div style={{ border: "1px solid #ced4da", height: "800px", margin: "0 20px" }}></div>
                      <div style={{ flex: 1 }}>
                        {apiResponse && (
                          <div>
                            <h4 className="mb-3">AI Suggestions</h4>
                            <h6>Optimized Title:</h6>
                            <div
                              style={{
                                border: "1px solid #ced4da",
                                borderRadius: "0.25rem",
                                padding: "0.375rem",
                                backgroundColor: "#fff",
                                minHeight: "50px",
                                overflow: "hidden",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {apiResponse.result.optimized.title}
                            </div>
                            <h6 className="mt-2">Optimized Description:</h6>
                            <div
                              style={{
                                border: "1px solid #ced4da",
                                borderRadius: "0.25rem",
                                padding: "0.375rem",
                                backgroundColor: "#fff",
                                minHeight: "100px",
                                overflow: "hidden",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {apiResponse.result.optimized.description}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="p-2 tw-justify-center">
                      <h4 className="mt-2">Improvements</h4>
                      <div>
                        <div>
                          {apiResponse.result.analysis.improvements &&
                          typeof apiResponse.result.analysis.improvements === "object" ? (
                            Object.keys(apiResponse.result.analysis.improvements).map((key) => {
                              const value = apiResponse.result.analysis.improvements[key];
                              if (Array.isArray(value)) {
                                return (
                                  <div key={key}>
                                    <strong>{key}:</strong>
                                    <ul>
                                      {value.map((item, index) => (
                                        <li key={index}> {item}</li>
                                      ))}
                                    </ul>
                                  </div>
                                );
                              } else if (typeof value === "string") {
                                return (
                                  <div key={key}>
                                    <strong>{key}:</strong> {value}
                                  </div>
                                );
                              }
                              return null; // Skip if not an array or string
                            })
                          ) : (
                            <div>No analysis data available.</div>
                          )}
                        </div>
                        <h6 className="mt-2">Readability Score:</h6>
                        <div>
                          Original:{" "}
                          <span className="badge bg-danger me-2">
                            {apiResponse.result.analysis.readability_score.original}
                          </span>
                          Optimized:{" "}
                          <span className="badge bg-success">
                            {apiResponse.result.analysis.readability_score.original}
                          </span>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <ButtonTheme
                      type="button"
                      className="btn btn-primary mb-3"
                      onClick={handleCallOptimizerAPI} // Call the API on button click
                    >
                      Optimize with AI
                    </ButtonTheme>
                  </ModalFooter>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </div>
  );
};

export default withRouter(ProductDetailsPage);
