import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardFooter,
  Alert,
  Button,
} from "reactstrap";
import {
  authorizationConnection,
  getConnectionDetails,
  getConnectionSetupFields,
  getFormSyncRecordsAPI,
  setFetchActionSettings,
  setPublishActionSettings,
  testConnectionAPI,
  updateConnectionSettings,
  updateDynamicSettings,
  updateWebHookSettings,
  updateConnectionStatus,
} from "../../../../../helpers/service_helper";
import withRouter from "../../../../../Components/Common/withRouter";
import BreadCrumbCustom from "../../../../../Components/Common/BreadCrumbCustom";
import {
  convertToPascalCase,
  deepCopy,
  isValidJson,
  snakeToCapitalizedWords,
} from "../../../../../helpers/format_helper";
import InputTheme from "../../../../../Components/Common/InputTheme";
import ScrollToError from "../../../../../Components/Common/ScrollToError";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import Loader from "../../../../../Components/Common/Loader";
import { useDispatch } from "react-redux";
import ImageCustom from "../../../../../Components/Common/ImageCustom";
import { toast } from "react-toastify";
import classnames from "classnames";
import SelectTheme from "../../../../../Components/Common/SelectTheme";
import { useTranslation } from "react-i18next";
import BadgeCustom from "../../../../../Components/Common/BadgeCustom";
import SearchInput from "../../../../../Components/Common/SearchInput";
import DynamicSettingsForm from "./DynamicSettingsForm";
import DynamicSearchForm from "./SearchConnectionForm";
import { FaCopy } from "react-icons/fa";
import "ace-builds/webpack-resolver";

const ConfigConnectionPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  const { id } = params;
  const dispatch = useDispatch();
  const [connection, setConnection] = useState(null);

  const [formFields, setFormFields] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState({});
  const [loading, setLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [loadingSchema, setLoadingSchema] = useState(false);
  const formRef = useRef();

  const [selectedAction, setSelectedAction] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [isActive, setIsActive] = useState("");

  useEffect(() => {
    // Fetch the initial connection status when the component mounts
    const fetchConnectionStatus = async () => {
      try {
        setLoading(true);
        const response = await getConnectionDetails(id);
        setIsActive(response.status); // Set the initial status ("ACTIVE" or "INACTIVE")
      } catch (error) {
        toast.error(`Failed to fetch connection status: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchConnectionStatus();
  }, [id]);

  const handleToggleStatus = async () => {
    try {
      setLoading(true);

      const response = await updateConnectionStatus(id);
      const message = response.message.trim();

      let newStatus;
      if (message === "Activate connection successfully!") {
        setIsActive("ACTIVE");
        newStatus = "ACTIVE";
      } else if (message === "Deactivate connection successfully!") {
        setIsActive("INACTIVE");
        newStatus = "INACTIVE";
      } else {
        console.warn("Unexpected API response message:", response.message);
        return;
      }

      setConnection((prevConnection) => ({
        ...prevConnection,
        status: newStatus,
      }));

      toast.success(response.message);
    } catch (error) {
      console.error(`Failed to update connection status: ${error.message}`);
      toast.error(`Failed to update connection status: ${error.message}`);
    } finally {
      setLoading(false);
      console.log("Loading state set to false");
    }
  };

  const convertToTitleCase = (str) => {
    return str
      .split(/[_\/]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleSaveActionSettings = async (values) => {
    try {
      setLoading(true);

      const processActions = (actionData) => {
        const { actions, ...checkedActions } = actionData;
        const payload = { actions: {} };

        Object.keys(checkedActions).forEach((item) => {
          if (checkedActions[item] && actions[item]) {
            payload.actions[item] = actions[item];
          }
        });
        return payload;
      };
      const fetch_actions_payload = processActions(deepCopy(values.fetch_actions));
      const publish_actions_payload = processActions(deepCopy(values.publish_actions));
      await setFetchActionSettings(id, fetch_actions_payload);
      await setPublishActionSettings(id, publish_actions_payload);
      toast.success(t("Save action settings successfully!"));
    } catch (e) {
      toast.error(String(e));
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateWebhookSettings = async (values) => {
    try {
      setLoading(true);
      const payload = {
        webhook_settings: values,
      };
      const res = await updateWebHookSettings(id, payload);
      toast.success(res?.message);
    } catch (e) {
      toast.error(String(e));
    } finally {
      setLoading(false);
    }
  };

  const actionValidation = {
    enableReinitialize: true,
    initialValues: {
      fetch_actions: {
        actions: Object.values(connection?.action_groups ?? {}).reduce((total, item) => {
          const newData = { ...total, ...item.fetch_actions };
          return newData;
        }, {}),
        ...Object.values(connection?.action_groups ?? {}).reduce((total, item) => {
          const newData = {
            ...total,
            ...Object.keys(item.fetch_actions).reduce((subTotal, subItem) => {
              subTotal[subItem] = true;
              return subTotal;
            }, {}),
          };
          return newData;
        }, {}),
      },
      publish_actions: {
        actions: Object.values(connection?.action_groups ?? {}).reduce((total, item) => {
          const newData = { ...total, ...item.publish_actions };
          return newData;
        }, {}),
        ...Object.values(connection?.action_groups ?? {}).reduce((total, item) => {
          const newData = {
            ...total,
            ...Object.keys(item.publish_actions).reduce((subTotal, subItem) => {
              subTotal[subItem] = true;
              return subTotal;
            }, {}),
          };
          return newData;
        }, {}),
      },
    },
    onSubmit: (values) => {
      handleSaveActionSettings(values);
    },
  };
  const actionFormRef = useRef();

  const webHookValidation = {
    enableReinitialize: true,
    initialValues: deepCopy(connection?.webhook_settings ?? {}),
    onSubmit: (values) => {
      handleUpdateWebhookSettings(values);
    },
  };
  const webHookFormRef = useRef();

  const [authorizeLoading, setAuthorizeLoading] = useState(false);
  const authorize = async () => {
    try {
      setAuthorizeLoading(true);
      const res = await authorizationConnection(id);
      window.open(res.authorization_url, "_blank");
    } catch (e) {
      toast.error(String(e));
    } finally {
      setAuthorizeLoading(false);
    }
  };

  // Define the tabs and their corresponding query parameters
  const tabs = [
    { name: "Actions", queryParam: "actions" },
    { name: "Webhook", queryParam: "webhook" },
    { name: "Credentials", queryParam: "credentials" },
    { name: "Settings", queryParam: "settings" },
  ];

  // Determine the initial tab based on the query parameter in the URL
  const initialTab = tabs.find((tab) => tab.queryParam === new URLSearchParams(location.search).get("tab")) || tabs[0];
  const [selectedTab, setSelectedTab] = useState(initialTab.name);

  const toggleCustom = (tab) => {
    if (selectedTab !== tab.name) {
      setSelectedTab(tab.name);
      setActionType(null);
      setSelectedAction(null);
      // Update the URL with the new query parameter
      navigate(`?tab=${tab.queryParam}`);
    }
  };

  useEffect(() => {
    const fetchFields = async () => {
      try {
        setLoadingSchema(true);
        const connectionRes = await getConnectionDetails(id);
        setConnection(connectionRes);
        setLocationData(connectionRes?.dynamic_settings ?? {});
        const res = await getConnectionSetupFields(connectionRes?.channel_name);
        const settingSchema =
          res?.setup_fields?.definitions?.[`${convertToPascalCase(connectionRes?.channel_name)}SettingsSchema`] || {};
        const fields = Object.entries(settingSchema.properties || {}).map(([key, value]) => ({
          name: key,
          label:
            settingSchema.required && settingSchema.required.includes(key) ? (
              <>
                {snakeToCapitalizedWords(key)} <span style={{ color: "red" }}>*</span>
              </>
            ) : (
              snakeToCapitalizedWords(key)
            ),
          type:
            value.type === "string"
              ? settingSchema.required && settingSchema.required.includes(key)
                ? "password"
                : "text"
              : value.type,
          placeholder: `Enter ${snakeToCapitalizedWords(key)}`,
        }));

        setFormFields(fields);
        setInitialValues(connectionRes?.settings ?? {});

        const validationObj = Yup.object().shape(
          Object.keys(settingSchema.properties || {}).reduce((acc, key) => {
            const field = settingSchema.properties[key];
            let fieldSchema = Yup.mixed();

            if (field.type === "string") {
              fieldSchema = Yup.string();
              // if (key.toLowerCase().includes("url")) {
              //   fieldSchema = fieldSchema.url(`Invalid URL format for ${convertCamelCaseToWords(key)}`);
              // }
            } else if (field.type === "number") {
              fieldSchema = Yup.number();
            } else if (field.type === "boolean") {
              fieldSchema = Yup.boolean();
            }

            if (settingSchema.required && settingSchema.required.includes(key)) {
              fieldSchema = fieldSchema.required(`${snakeToCapitalizedWords(key)} is required`);
            }

            acc[key] = fieldSchema;
            return acc;
          }, {}),
        );
        setValidationSchema(validationObj);
      } catch (error) {
        console.error("Error fetching form fields:", error);
      } finally {
        setLoadingSchema(false);
      }
    };
    fetchFields();
  }, [id]);

  const handleUpdateSettings = async () => {
    try {
      console.log(formRef.current.values);
      setLoading(true);
      const payload = {
        settings: formRef.current.values,
      };
      const res = await updateConnectionSettings(id, payload);
      toast.success(t(res?.message));
      if (res?.authorization_url) {
        window.open(res.authorization_url, "_blank");
      }
    } catch (e) {
      toast.error(String(e));
    } finally {
      setLoading(false);
    }
  };

  const handleTestConnection = async () => {
    try {
      setTestLoading(true);
      const res = await testConnectionAPI(id);
      if (res?.status === "failure") {
        toast.error(t(res?.message));
      } else {
        toast.success(t(res?.message));
      }
    } catch (e) {
      toast.error(String(e));
    } finally {
      setTestLoading(false);
    }
  };

  const [mappings, setMappings] = useState({});
  const [locationData, setLocationData] = useState({});
  const [externalLocations, setExternalLocations] = useState([]);
  const [masterLocations, setMasterLocations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchAction, setSearchAction] = useState(false);
  const [dataFormSearch, setDataFormSearch] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState("");

  const [quickMapLoading, setQuickMapLoading] = useState(false);
  const [saveMapLoading, setSaveMapLoading] = useState(false);

  const handleMapping = (externalId, masterId) => {
    setMappings((prevMappings) => ({
      ...prevMappings,
      [externalId]: masterId,
    }));
  };

  const handleSaveMapping = async () => {
    try {
      setSaveMapLoading(true);
      await updateDynamicSettings(id, mappings);
      toast.success(t("Mappings saved successfully!"));
    } catch (e) {
      toast.error(t("Mappings saved failed!"));
    } finally {
      setSaveMapLoading(false);
    }
  };

  const handleQuickMap = async () => {
    try {
      setQuickMapLoading(true);
      const newMappings = deepCopy(mappings);
      externalLocations.forEach((external) => {
        if (!newMappings[external.id]) {
          const match = masterLocations.find((master) =>
            master.name.toLowerCase().includes(external.name.toLowerCase()),
          );
          if (match) {
            newMappings[external.id] = match.id;
          }
        }
      });
      setMappings(newMappings);
      toast.success(t("Quick mapping applied!"));
    } catch (e) {
      toast.error(t("Quick mapping failed!"));
    } finally {
      setQuickMapLoading(false);
    }
  };

  const filteredExternalLocations = useMemo(() => {
    return externalLocations.filter(
      (location) =>
        location.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        location.address.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [externalLocations, searchTerm]);

  useEffect(() => {
    if (locationData && locationData.location_mapping) {
      setExternalLocations(locationData.location_mapping.external || []);
      setMasterLocations(locationData.location_mapping.master || []);
      setMappings(locationData.location_mapping.mapping || {});
    }
  }, [locationData]);

  const getButtonText = () => {
    if (loading) return "Updating...";
    return isActive === "ACTIVE" ? "Deactivate" : "Activate";
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumbCustom text={t("Back to channel")} navigateTo="/channels">
          <div className="hstack gap-2">
            <ButtonTheme className="btn btn-primary w-md" loading={testLoading} onClick={handleTestConnection}>
              {t("Test connection")}
            </ButtonTheme>
            <Button
              color={isActive === "ACTIVE" ? "danger" : "success"}
              className="btn w-md"
              onClick={handleToggleStatus}
              disabled={loading}
            >
              {(() => {
                return loading ? "Updating..." : isActive === "ACTIVE" ? "Deactivate" : "Activate";
              })()}
            </Button>
          </div>
        </BreadCrumbCustom>

        {loadingSchema ? (
          <Loader />
        ) : (
          <Card>
            <CardHeader>
              <Row className="align-items-center gy-3">
                <div className="col-sm">
                  <h5 className="card-title mb-0 hstack justify-content-between">
                    {connection?.name} {t("Configuration")} <BadgeCustom status={connection?.status} />
                  </h5>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              {searchAction ? (
                <DynamicSearchForm
                  action={isButtonLoading}
                  onBack={() => {
                    setActionType(null);
                    setSelectedAction(null);
                    setSearchAction(null);
                  }}
                  dataFormSearch={dataFormSearch}
                />
              ) : (
                <Row className="g-3">
                  <Col xs={12}>
                    <div className="hstack gap-3">
                      <ImageCustom
                        image={connection?.logo}
                        name={connection?.name}
                        avatarSize={"avatar-md"}
                        isPreview={false}
                      />
                      <p className="mb-0">{connection?.description}</p>
                    </div>
                  </Col>

                  <Col xs={12}>
                    <Nav className="nav-tabs nav-tabs-custom nav-primary mb-3" role="tablist">
                      {tabs.map((tab, index) => (
                        <NavItem key={index}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: selectedTab === tab.name,
                            })}
                            onClick={() => {
                              toggleCustom(tab);
                            }}
                          >
                            {t(tab.name)}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>

                    <TabContent activeTab={selectedTab} className="text-muted">
                      <TabPane tabId={tabs[0].name} id={tabs[0].name}>
                        <Formik
                          enableReinitialize={true}
                          initialValues={actionValidation.initialValues}
                          validationSchema={actionValidation.validationSchema}
                          onSubmit={actionValidation.onSubmit}
                          innerRef={actionFormRef}
                        >
                          {({ values, submitForm, setFieldValue }) => (
                            <Form>
                              {!selectedAction && !actionType ? (
                                <div className="space-y-6">
                                  <div>
                                    {Object.values(connection?.action_groups ?? {})
                                      .filter(
                                        (item) =>
                                          Object.keys(item.fetch_actions).length !== 0 ||
                                          Object.keys(item.publish_actions).length !== 0,
                                      )
                                      .map((item, index) => (
                                        <Row key={index}>
                                          <Col lg={12}>
                                            <h5 className="card-title flex-grow-1 mb-2">
                                              {t(snakeToCapitalizedWords(item.group_name))}
                                            </h5>
                                          </Col>
                                          <Col lg={6}>
                                            {Object.keys(item.fetch_actions).map((action) => (
                                              <div
                                                key={action}
                                                className="hstack align-items-center justify-content-between mb-2"
                                              >
                                                <InputTheme
                                                  type="checkbox"
                                                  name={`fetch_actions.actions.${action}.enabled`}
                                                  label={action}
                                                  formNoMargin={true}
                                                />
                                                <div className="gap-2 d-flex">
                                                  <ButtonTheme
                                                    loading={loadingSearch && isButtonLoading === action}
                                                    className="btn btn-outline-primary"
                                                    onClick={async () => {
                                                      setIsButtonLoading(action);
                                                      setLoadingSearch(true);
                                                      try {
                                                        const response = await getFormSyncRecordsAPI(id, {
                                                          action_type: action,
                                                          params: {
                                                            transform_type: "bill", // order/bill for get_order action_type. If don't pass this, default bill
                                                          },
                                                        });
                                                        const mapFormSearch = Object.keys(response.data).map(
                                                          (item) => ({
                                                            key: item,
                                                            type: response?.data?.[item].split("|")[0],
                                                            format:
                                                              response?.data?.[item].split("|").length > 1
                                                                ? response?.data?.[item].split("|")[1]
                                                                : null,
                                                          }),
                                                        );
                                                        setLoadingSearch(false);
                                                        setSearchAction(true);
                                                        setDataFormSearch(mapFormSearch);
                                                      } catch (error) {
                                                        setLoadingSearch(false);
                                                        toast.error(t("Software not supported"));
                                                      }
                                                    }}
                                                  >
                                                    <i className="ri-search-line" />
                                                  </ButtonTheme>
                                                  <ButtonTheme
                                                    className="btn btn-outline-primary"
                                                    onClick={() => {
                                                      setSelectedAction(action);
                                                      setActionType("fetch_actions");
                                                    }}
                                                  >
                                                    <i className="ri-settings-5-line"></i>
                                                  </ButtonTheme>
                                                </div>
                                              </div>
                                            ))}
                                          </Col>
                                          <Col lg={6}>
                                            {Object.keys(item.publish_actions).map((action) => (
                                              <div
                                                key={action}
                                                className="hstack align-items-center justify-content-between mb-2"
                                              >
                                                <InputTheme
                                                  type="checkbox"
                                                  name={`publish_actions.actions.${action}.enabled`}
                                                  label={action}
                                                  formNoMargin={true}
                                                />
                                                <ButtonTheme
                                                  className="btn btn-outline-primary"
                                                  onClick={() => {
                                                    setSelectedAction(action);
                                                    setActionType("publish_actions");
                                                  }}
                                                >
                                                  <i className="ri-settings-5-line"></i>
                                                </ButtonTheme>
                                              </div>
                                            ))}
                                          </Col>
                                        </Row>
                                      ))}
                                  </div>
                                  <div className="hstack gap-2 justify-content-end">
                                    <ButtonTheme
                                      loading={loading}
                                      loadShowText={true}
                                      disabled={loading}
                                      className="btn btn-outline-primary"
                                      onClick={submitForm}
                                    >
                                      {t("Save Action Settings")}
                                    </ButtonTheme>
                                  </div>
                                </div>
                              ) : (
                                <ActionSetting
                                  initialValues={values?.[actionType]?.actions?.[selectedAction]}
                                  onChangeData={(data) => {
                                    setFieldValue(`${[actionType]}.actions.${[selectedAction]}`, data);
                                    setActionType(null);
                                    setSelectedAction(null);
                                  }}
                                  onBack={() => {
                                    setActionType(null);
                                    setSelectedAction(null);
                                  }}
                                  selectedAction={selectedAction}
                                  actionType={actionType}
                                />
                              )}
                            </Form>
                          )}
                        </Formik>
                      </TabPane>
                      <TabPane tabId={tabs[1].name} id={tabs[1].name}>
                        <Formik
                          enableReinitialize={true}
                          initialValues={webHookValidation.initialValues}
                          validationSchema={webHookValidation.validationSchema}
                          onSubmit={webHookValidation.onSubmit}
                          innerRef={webHookFormRef}
                        >
                          {({ values, submitForm, setFieldValue }) => (
                            <Form>
                              {Object.keys(connection?.webhook_settings ?? {}).map((item, index) => (
                                <InputTheme key={index} type="checkbox" name={item} label={convertToTitleCase(item)} />
                              ))}
                              <div className="hstack gap-2 justify-content-end">
                                <ButtonTheme
                                  loading={loading}
                                  loadShowText={true}
                                  disabled={loading}
                                  className="btn btn-outline-primary"
                                  onClick={submitForm}
                                >
                                  {t("Save Webhook Settings")}
                                </ButtonTheme>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </TabPane>
                      <TabPane tabId={tabs[2].name} id={tabs[2].name}>
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={handleUpdateSettings}
                          enableReinitialize
                          innerRef={formRef}
                        >
                          {({ submitForm }) => (
                            <Form>
                              <ScrollToError />
                              {formFields.map((field) => (
                                <InputTheme
                                  type={field.type}
                                  key={field.name}
                                  name={field.name}
                                  label={field.label}
                                  placeholder={field.placeholder}
                                />
                              ))}
                              <div className="hstack gap-2 justify-content-end">
                                {connection?.auth_type === "OAUTH" && (
                                  <ButtonTheme
                                    loading={authorizeLoading}
                                    loadShowText={true}
                                    disabled={authorizeLoading}
                                    className="btn btn-outline-primary"
                                    onClick={authorize}
                                  >
                                    {t("Re-authorize")}
                                  </ButtonTheme>
                                )}
                                <ButtonTheme
                                  loading={loading}
                                  loadShowText={true}
                                  disabled={loading}
                                  className="btn btn-outline-primary"
                                  onClick={handleUpdateSettings}
                                >
                                  {t("Update Settings")}
                                </ButtonTheme>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </TabPane>
                      <TabPane tabId={tabs[3].name} id={tabs[3].name}>
                        <DynamicSettingsForm id={id} initialDynamicSettings={connection?.dynamic_settings || {}} />
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        )}
      </Container>
    </div>
  );
};

const ActionSetting = ({ initialValues, onChangeData = () => {}, onBack = () => {}, selectedAction, actionType }) => {
  const { t } = useTranslation();
  const validation = {
    enableReinitialize: true,
    initialValues: {
      enabled: initialValues?.enabled,
      rate_limit: initialValues?.rate_limit,
      max_retries: initialValues?.retry_settings?.max_retries,
      retry_delay: initialValues?.retry_settings?.retry_delay,
      retry_backoff: initialValues?.retry_settings?.retry_backoff,
      custom_settings: initialValues?.custom_settings ? JSON.stringify(initialValues?.custom_settings) : "",

      // Convert destinations object to an array
      destinations: initialValues?.destinations
        ? Object.entries(initialValues.destinations).map(([id, destination]) => ({
            id,
            enable: destination.enable,
            type: destination.type,
          }))
        : [],

      ...(actionType === "fetch_actions" && {
        schedule_type: initialValues?.schedule?.type,
        schedule_value: initialValues?.schedule?.value,
      }),
    },

    validationSchema: Yup.object({
      enabled: Yup.boolean(),
      rate_limit: Yup.string().required(t("Please Enter Rate Limit")),
      custom_settings: Yup.string().required(t("Please Enter Custom Settings")),
      destinations: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(),
          enable: Yup.boolean(),
          type: Yup.string().required(t("Please Enter Destination Type")),
        }),
      ),
      ...(actionType === "fetch_actions" && {
        schedule_type: Yup.string().required(t("Please Select Schedule Type")),
        schedule_value: Yup.string().required(t("Please Enter Schedule Value")),
      }),
    }),

    onSubmit: (values) => {
      const isValidJsonString = isValidJson(values?.custom_settings);
      if (!isValidJsonString) {
        toast.error(t("Field custom settings is invalid json"));
        return;
      }

      const formattedDestinations = values.destinations.reduce((acc, destination) => {
        acc[destination.id] = {
          enable: destination.enable,
          type: destination.type,
        };
        return acc;
      }, {});

      const newData = {
        enabled: values.enabled,
        retry_settings: {
          max_retries: values.max_retries,
          retry_delay: values.retry_delay,
          retry_backoff: values.retry_backoff,
        },
        rate_limit: values.rate_limit,
        custom_settings: JSON.parse(values.custom_settings),
        destinations: formattedDestinations,
      };

      if (actionType === "fetch_actions") {
        newData.status = {
          last_run: null,
          next_run: null,
        };
        newData.response_mapping = {};
        newData.schedule = {
          type: values.schedule_type,
          value: values.schedule_value,
        };
      } else {
        newData.payload_template = {};
      }

      onChangeData(newData);
    },
  };

  const formRef = useRef();
  return (
    <Formik
      enableReinitialize={true}
      initialValues={validation.initialValues}
      validationSchema={validation.validationSchema}
      onSubmit={validation.onSubmit}
      innerRef={formRef}
    >
      {({ values, submitForm, resetForm, setFieldValue }) => (
        <Form>
          <h5 className="card-title flex-grow-1 mb-4">
            {t(snakeToCapitalizedWords(selectedAction))} {t("Settings")}
          </h5>

          {/* Render Destinations */}
          <div
            className="row g-3 tw-justify-center me-2"
            style={{
              display: "flex",
              flexWrap: "wrap", // Allow wrapping of items
              justifyContent: "center", // Center items horizontally
              gap: "16px", // Space between cards
            }}
          >
            {values.destinations?.map((destination, index) => (
              <div
                key={destination.id}
                className="card p-4 shadow-sm"
                style={{
                  width: "100%",
                  maxWidth: "500px",
                  border: "1px solid #dadada",
                  borderRadius: "12px",
                  padding: "20px",
                }}
              >
                <div className="d-flex align-items-center justify-content-between" style={{ width: "100%" }}>
                  {/* Destination Type and ID */}
                  <div className="d-flex flex-column">
                    <span className="fw-bold fs-5" style={{ color: "#333" }}>
                      {destination.type || "Destination"}
                    </span>
                    <div className="text-muted">
                      ID: {destination.id}
                      <FaCopy
                        style={{ cursor: "pointer", marginLeft: "8px" }}
                        onClick={() => {
                          navigator.clipboard.writeText(destination.id);
                          toast.success("ID copied to clipboard!");
                        }}
                      />
                    </div>
                  </div>

                  {/* Toggle Switch */}
                  <div className="form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`destinations-${index}-enable`}
                      name={`destinations[${index}].enable`}
                      checked={values.destinations[index]?.enable}
                      onChange={(e) => setFieldValue(`destinations[${index}].enable`, e.target.checked)}
                      style={{ transform: "scale(1.75)", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* General Settings */}
          <div
            className="card shadow-sm mb-4 p-4"
            style={{
              border: "1px solid #dadada",
              borderRadius: "8px",
            }}
          >
            <div
              className="form-group d-flex align-items-center"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                gap: "16px",
                maxWidth: "100%",
              }}
            >
              <label htmlFor="enabled" className="form-label me-3" style={{ marginBottom: 0 }}>
                {t("Enabled")}
              </label>
              <div className="form-switch">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="enabled"
                  name="enabled"
                  checked={values.enabled}
                  onChange={(e) => setFieldValue("enabled", e.target.checked)}
                  style={{ transform: "scale(1.5)" }}
                />
              </div>
            </div>

            {/* Rate Limit Input */}
            <div className="form-group mt-3">
              <InputTheme name="rate_limit" label="Rate Limit" placeholder="Enter rate limit" />
            </div>

            {/* Max Retries Input */}
            <div className="form-group mt-3">
              <InputTheme name="max_retries" label="Max retries" placeholder="Enter max retries" />
            </div>

            {/* Retry Delay Input */}
            <div className="form-group mt-3">
              <InputTheme name="retry_delay" label="Retry delay" placeholder="Enter retry delay" />
            </div>

            {/* Retry Backoff Input */}
            <div className="form-group mt-3">
              <InputTheme name="retry_backoff" label="Retry backoff" placeholder="Enter retry backoff" />
            </div>

            {/* Conditional Fields for fetch_actions */}
            {actionType === "fetch_actions" && (
              <>
                <div className="form-group mt-3">
                  <SelectTheme
                    label="Schedule Type"
                    name="schedule_type"
                    options={[
                      { id: "interval", name: "Interval" },
                      { id: "cron", name: "Cron" },
                    ]}
                  />
                </div>
                <div className="form-group mt-3">
                  <InputTheme name="schedule_value" label="Schedule Value" placeholder="Enter schedule value" />
                </div>
              </>
            )}

            {/* Custom Settings JSON Editor */}
            <div className="form-group mt-3">
              <InputTheme
                name="custom_settings"
                type="editJson"
                label="Custom Settings"
                placeholder="Enter custom JSON settings"
              />
            </div>
          </div>

          {/* Action Buttons */}
          <div className="d-flex justify-content-end gap-2">
            <ButtonTheme
              className="btn btn-outline-secondary"
              onClick={() => {
                onBack();
                resetForm();
              }}
            >
              {t("Back")}
            </ButtonTheme>
            <ButtonTheme className="btn btn-primary" onClick={submitForm}>
              {t("Save")}
            </ButtonTheme>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(ConfigConnectionPage);
