import { Actions } from "../../../../Components/constants/common";
import {
  LIST_BLOG,
  DELETE_BLOG,
  DELETE_BLOG_SUCCESS,
  DELETE_BLOG_FAIL,
  ADD_BLOG_SUCCESS,
  ADD_BLOG_FAIL,
  UPDATE_BLOG_SUCCESS,
  UPDATE_BLOG_FAIL,
  LIST_BLOG_SUCCESS,
  LIST_BLOG_FAIL,
  ADD_NEW_BLOG,
  UPDATE_BLOG,
  GET_BLOG,
  GET_BLOG_SUCCESS,
  GET_BLOG_FAIL,
  RESET_BLOG_STATE,
} from "./actionType";

const INIT_STATE = {
  blogs: [],
  blog: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Blogs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_BLOG:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_BLOG_SUCCESS:
      return {
        ...state,
        message: "Get blogs success",
        loading: false,
        success: true,
        blogs: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_BLOG_FAIL:
      return {
        ...state,
        message: "Get blogs failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_BLOG:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_BLOG_SUCCESS:
      return {
        ...state,
        message: "Get blog success",
        loading: false,
        success: true,
        blog: action.payload,
        action: Actions.EMPTY,
      };

    case GET_BLOG_FAIL:
      return {
        ...state,
        message: "Get blog failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_BLOG:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_BLOG_SUCCESS:
      return {
        ...state,
        message: "Delete blog success",
        loading: false,
        success: true,
        blogs: state.blogs.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_BLOG_FAIL:
      return {
        ...state,
        message: "Delete blog failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_BLOG:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_BLOG_SUCCESS:
      return {
        ...state,
        message: "Add blog success",
        success: true,
        loading: false,
        blogs: [...state.blogs, action.payload],
        blog: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_BLOG_FAIL:
      return {
        ...state,
        message: "Add blog failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_BLOG:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_BLOG_SUCCESS:
      return {
        ...state,
        message: "Update blog success",
        loading: false,
        success: true,
        blog: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_BLOG_FAIL:
      return {
        ...state,
        message: "Update blog failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case RESET_BLOG_STATE:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Blogs;
