import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Row, Table } from "reactstrap";

import { convertValidDate, convertValidTime, snakeToCapitalizedWords } from "../../../../helpers/format_helper";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import Loader from "../../../../Components/Common/Loader";
import { Actions } from "../../../../Components/constants/common";
import { getImportFetchEvent } from "../../../../store/actions";
import withRouter from "../../../../Components/Common/withRouter";
import BreadCrumbCustom from "../../../../Components/Common/BreadCrumbCustom";
import JsonView from "react18-json-view";
import LottieIcon from "../../../../Components/Common/LottieIcon";
import SyncComponent from "../../../../Components/Common/SynceComponent";
import ImageCustom from "../../../../Components/Common/ImageCustom";

const FetchEventDetails = (props) => {
  document.title = "Fetch Event Details | OptiWarehouse";
  const {
    router: { navigate, location, params, t },
  } = props;
  const dispatch = useDispatch();
  const { id } = params;

  const { importFetchEvent, eventsLoading, eventsAction, eventMessage, eventsError } = useMemoizedSelector((state) => ({
    importFetchEvent: state.FetchEvents.importFetchEvent,
    eventsLoading: state.FetchEvents.loading,
    eventsAction: state.FetchEvents.action,
    eventsError: state.FetchEvents.error,
    eventMessage: state.FetchEvents.message,
  }));

  const { saleChannels } = useMemoizedSelector((state) => ({
    saleChannels: state.SaleChannels.saleChannels,
  }));

  useEffect(() => {
    id && dispatch(getImportFetchEvent(id));
  }, [id]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumbCustom text="Back to fetch event list" navigateTo="/fetch-events" />
        <Row>
          <Col lg={12}>
            {eventsAction === Actions.GET ? (
              <Loader />
            ) : eventsError ? (
              <div className="text-center">
                <LottieIcon src="https://cdn.lordicon.com/msoeawqm.json" style={{ width: "130px", height: "130px" }} />
                <div>
                  <h4>{t(eventsError)}</h4>
                </div>
              </div>
            ) : (
              <Fragment>
                <h5 className="card-title mb-3">
                  Fetch Event Details of {id}
                  <span className="badge bg-primary rounded-pill align-middle ms-1 filter-badge">
                    {importFetchEvent ? importFetchEvent.length : 0}
                  </span>
                </h5>
                {importFetchEvent.map((item, index) => {
                  const saleChannel = saleChannels.find((saleChannel) => saleChannel.key === item?.channel);
                  return (
                    <Card key={index}>
                      <CardHeader>
                        <h5 className="card-title">
                          {index + 1}. {item?.transformed_record_id || "No ID"}
                        </h5>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col lg={6}>
                            <div className="table-responsive">
                              <Table className="table mb-0 table-borderless" style={{ tableLayout: "fixed" }}>
                                <tbody>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Action Group:</span>
                                    </th>
                                    <td>{item?.action_group || "---"}</td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Connection id:</span>
                                    </th>
                                    <td>{item?.connection_id || "---"}</td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Action type:</span>
                                    </th>
                                    <td>{item?.action_type || "---"}</td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Event source:</span>
                                    </th>
                                    <td>{item?.event_source || "---"}</td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Retry count:</span>
                                    </th>
                                    <td>{item?.retry_count || "---"}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="table-responsive">
                              <Table className="table mb-0 table-borderless" style={{ tableLayout: "fixed" }}>
                                <tbody>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">object data:</span>
                                    </th>
                                    <td>
                                      {item?.object_data ? (
                                        <JsonView src={item.object_data} collapsed={true} enableClipboard={true} />
                                      ) : (
                                        "---"
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Continuation Token:</span>
                                    </th>
                                    <td>{item?.continuation_token || "---"}</td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Object id:</span>
                                    </th>
                                    <td>{item?.object_id || "---"}</td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Event time:</span>
                                    </th>
                                    <td>
                                      {convertValidDate(item?.event_time)},{" "}
                                      <small>{convertValidTime(item?.event_time)}</small>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Created At:</span>
                                    </th>
                                    <td>
                                      {convertValidDate(item?.created_at)},{" "}
                                      <small>{convertValidTime(item?.created_at)}</small>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <span className="fw-medium">Updated At:</span>
                                    </th>
                                    <td>
                                      {convertValidDate(item?.updated_at)},{" "}
                                      <small>{convertValidTime(item?.updated_at)}</small>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  );
                })}
              </Fragment>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(FetchEventDetails);
